import { Box } from "@mui/material";
import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import React from "react";
import Iconify from "src/components/Iconify";

export default function BadgeExample() {
  const [count, setCount] = React.useState(1);
  const [invisible, setInvisible] = React.useState(false);

  const handleBadgeVisibility = () => {
    setInvisible(!invisible);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        "& > *": {
          mb: 2,
        },
        "& > div > span": {
          mr: 4,
        },
      }}
    >
      <div>
        <Badge color="primary" badgeContent={count}>
          <Iconify icon="material-symbols:mail" />
        </Badge>
        <Badge color="secondary" badgeContent={count}>
          <Iconify icon="material-symbols:mail" />
        </Badge>
        <Badge color="error" badgeContent={count}>
          <Iconify icon="material-symbols:mail" />
        </Badge>
        <ButtonGroup>
          <Button
            aria-label="reduce"
            onClick={() => {
              setCount(Math.max(count - 1, 0));
            }}
          >
            <Iconify icon="material-symbols:remove" />
          </Button>
          <Button
            aria-label="increase"
            onClick={() => {
              setCount(count + 1);
            }}
          >
            <Iconify icon="material-symbols:add" />
          </Button>
        </ButtonGroup>
      </div>
      <div>
        <Badge color="primary" variant="dot" invisible={invisible}>
          <Iconify icon="material-symbols:mail" />
        </Badge>
        <Badge color="secondary" variant="dot" invisible={invisible}>
          <Iconify icon="material-symbols:mail" />
        </Badge>
        <Badge color="error" variant="dot" invisible={invisible}>
          <Iconify icon="material-symbols:mail" />
        </Badge>
        <FormControlLabel
          control={
            <Switch
              color="primary"
              checked={!invisible}
              onChange={handleBadgeVisibility}
            />
          }
          label="Show Badge"
        />
      </div>
    </Box>
  );
}
