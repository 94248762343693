import { Fragment, useEffect, useReducer } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Box,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";

function descendingComparator(a, b, orderBy) {
  if (a[orderBy] == null && b[orderBy] == null) {
    return 0;
  }
  if (a[orderBy] == null) {
    return 1;
  }
  if (b[orderBy] == null) {
    return -1;
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function sortStateReducer(state, action) {
  switch (action.type) {
    case "sort_table": {
      return {
        order: action.order,
        orderBy: action.orderBy,
        sortedData: state.sortedData.sort(
          getComparator(action.order, action.orderBy)
        ),
      };
    }
    case "refresh_data": {
      return {
        ...state,
        sortedData: action.newData.sort(
          getComparator(state.order, state.orderBy)
        ),
      };
    }
    default: {
      throw Error("Unknown action: " + action.type);
    }
  }
}

function createInitialState({ initialData, initialOrder, initialOrderBy }) {
  return {
    order: initialOrder,
    orderBy: initialOrderBy,
    sortedData: initialData?.sort(getComparator(initialOrder, initialOrderBy)),
  };
}

function SortableTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ ...headCell.sx }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function SortableTable(props) {
  const {
    data,
    headCells,
    header,
    children,
    mapFunction,
    initialOrder = "asc",
    initialOrderBy = "",
  } = props;
  const [sortState, sortDispatch] = useReducer(
    sortStateReducer,
    {
      initialData: data,
      initialOrder: initialOrder,
      initialOrderBy: initialOrderBy,
    },

    createInitialState
  );
  const handleRequestSort = (event, property) => {
    const isAsc = sortState.orderBy === property && sortState.order === "asc";
    sortDispatch({
      type: "sort_table",
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    });
  };

  // On data change, reset table
  useEffect(() => {
    sortDispatch({
      type: "refresh_data",
      newData: data,
    });
  }, [data]);

  return (
    <Fragment>
      {header}
      <TableContainer>
        <Table size="small">
          <SortableTableHead
            order={sortState.order}
            orderBy={sortState.orderBy}
            onRequestSort={handleRequestSort}
            headCells={headCells}
          />
          <TableBody>{sortState.sortedData.map(mapFunction)}</TableBody>
        </Table>
      </TableContainer>
      {children}
    </Fragment>
  );
}
