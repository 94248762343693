import {
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
} from "@mui/material";
import FormikTextField from "src/components/formik/FormikTextField";

export default function BroadAndPhraseCard(props) {
  const { title, touched, errors, values, handleChange } = props;
  return (
    <Card>
      <CardHeader titleTypographyProps={{ variant: "h5" }} title={title} />
      <CardContent>
        <Grid container spacing={1} justifyContent="space-between">
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  id="broadEnabled"
                  checked={values.broadEnabled || false}
                  onChange={handleChange}
                />
              }
              label="Broad Enabled"
            />
          </Grid>
          <Grid item xs={4}>
            <FormikTextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              formikName="broadMaxBid"
              label="Broad Max Bid"
              variant="standard"
              helperText={(touched.broadMaxBid && errors.broadMaxBid) || " "}
            />
          </Grid>
          <Grid item xs={4}>
            <FormikTextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              formikName="broadCurrentBid"
              label="Broad Current Bid"
              variant="standard"
              helperText={
                (touched.broadCurrentBid && errors.broadCurrentBid) || " "
              }
            />
          </Grid>
          <Grid item xs={4}>
            <FormikTextField
              fullWidth
              formikName="broadConversionRate"
              label="Broad Conversion Rate"
              variant="standard"
              helperText={
                (touched.broadConversionRate && errors.broadConversionRate) ||
                " "
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  id="phraseEnabled"
                  checked={values.phraseEnabled || false}
                  onChange={handleChange}
                />
              }
              label="Phrase Enabled"
            />
          </Grid>
          <Grid item xs={4}>
            <FormikTextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              formikName="phraseMaxBid"
              label="Phrase Max Bid"
              variant="standard"
              helperText={(touched.broadMaxBid && errors.broadMaxBid) || " "}
            />
          </Grid>
          <Grid item xs={4}>
            <FormikTextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              formikName="phraseCurrentBid"
              label="Phrase Current Bid"
              variant="standard"
              helperText={
                (touched.phraseCurrentBid && errors.phraseCurrentBid) || " "
              }
            />
          </Grid>
          <Grid item xs={4}>
            <FormikTextField
              fullWidth
              formikName="phraseConversionRate"
              label="Phrase Conversion Rate"
              variant="standard"
              helperText={
                (touched.phraseConversionRate && errors.phraseConversionRate) ||
                " "
              }
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
