import { TextField } from "@mui/material";
import { useField } from "formik";
// ----------------------------------------------------------------------

export default function FormikTextField({
  formikName,
  label,
  helperText,
  onChange = () => {},
  onBlur = () => {},
  ...props
}) {
  const [field, meta, helpers] = useField(formikName);

  return (
    <TextField
      id={field.name}
      name={field.name}
      label={label || field.name}
      helperText={
        meta.touched && Boolean(meta.error) ? meta.error : helperText || ""
      }
      error={meta.touched && Boolean(meta.error)}
      value={field.value !== undefined ? field.value : ""}
      onChange={(...args) => {
        field.onChange(...args);
        onChange(...args);
      }}
      onBlur={(...args) => {
        field.onBlur(...args);
        onBlur(...args);
      }}
      {...props}
    />
  );
}
