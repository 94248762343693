import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";
// @mui
import {
  Card,
  CardHeader,
  Box,
  Button,
  Typography,
  Grid,
  Stack,
  Link,
  Select,
  MenuItem,
  Collapse,
  IconButton,
} from "@mui/material";
// components
import { BaseOptionChart } from "../../components/chart";
import { mergeDeep } from "../../utils/mergeDeep";

import {
  useGroupKeywordStatisticsQuery,
  useCrawlProductGroupKeyword,
  useGetKeywordAlgorithmHistory,
  useGetKeywordUserHistory,
  useGetProductGroupKeywordAlgorithmHistory,
  useKeywordAmazonReportAggregate,
} from "src/hooks/productGroup";
import { LoadingButton } from "@mui/lab";
import { formatUtcToLocalDateTime } from "src/utils/formatTime";
import config from "src/utils/config";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { IsAdmin } from "src/slices/authSlice";
import Spinner from "src/components/Spinner";
import KeywordAlgorithmHistoryTable from "./KeywordAlgorithmHistoryTable";
import Iconify from "src/components/Iconify";

// ----------------------------------------------------------------------

KeywordSearchHistory.propTypes = {
  productId: PropTypes.number,
  keyword: PropTypes.object,
};

export default function KeywordSearchHistory({ productGroupId, keyword }) {
  const navigate = useNavigate();
  const isAdmin = useSelector(IsAdmin);
  const [daysToReturn, setDaysToReturn] = useState(1);
  const handleDaysToReturn = (event) => {
    setDaysToReturn(event.target.value);
  };
  const statsQuery = useGroupKeywordStatisticsQuery({
    productGroupId,
    keywordId: keyword?.id,
  });
  const keywordAlgorithmHistoryQuery = useGetKeywordAlgorithmHistory({
    productGroupId,
    keywordId: keyword?.id,
    daysToReturn: daysToReturn,
  });
  const keywordAlgorithmHistoryWithAveragesQuery =
    useGetProductGroupKeywordAlgorithmHistory({
      productGroupId,
      keywordId: keyword.id,
    });
  const keywordUserHistoryQuery = useGetKeywordUserHistory({
    productGroupId,
    keywordId: keyword?.id,
  });
  const keywordAmazonReportAggregateQuery = useKeywordAmazonReportAggregate(
    productGroupId,
    keyword.id
  );

  const algorithmHistory = keywordAlgorithmHistoryQuery?.data;
  const algorithmHistoryWithAveragesData =
    keywordAlgorithmHistoryWithAveragesQuery?.data;
  const algorithmHistoryWithAverages = algorithmHistoryWithAveragesData
    ? algorithmHistoryWithAveragesData[0]
    : {};
  const keywordAmazonReport = keywordAmazonReportAggregateQuery?.data;
  const timesAnalyzed = algorithmHistory?.filter(
    (x) => x.algoRunTime != null
  ).length;
  const bidChanges = algorithmHistory
    ?.filter((x) => x.algoRunTime != null)
    .filter((x) => x.currentBid !== x.originalBid).length;

  const crawlMutate = useCrawlProductGroupKeyword();

  const searchChartDateLabels = statsQuery.data?.dateAxis || [];
  const sponsoredResults = statsQuery.data?.dataSeries?.sponsored || [];
  const organicResults = statsQuery.data?.dataSeries?.organic || [];

  const recentSearches = [];
  for (var i = 1; i < 6 && searchChartDateLabels.length - i >= 0; i++) {
    recentSearches.push({
      date: searchChartDateLabels[searchChartDateLabels.length - i],
      organic: organicResults[searchChartDateLabels.length - i],
      sponsored: sponsoredResults[searchChartDateLabels.length - i],
    });
  }

  const algoChartDateLabels =
    algorithmHistory?.map((entry) => {
      return entry.searchRankTimestamp;
    }) || [];
  const algoChartSponsoredResults =
    algorithmHistory?.map((entry) => {
      return entry.paidRank;
    }) || [];
  const algoChartOrganicResults =
    algorithmHistory?.map((entry) => {
      return entry.organicRank;
    }) || [];
  const algoChartCurrentBid =
    algorithmHistory?.map((entry) => {
      return entry.currentBid;
    }) || [];

  const algoChartData = [
    {
      name: "Sponsored Rank",
      type: "line",
      fill: "solid",
      data: algoChartSponsoredResults,
    },
    {
      name: "Organic Rank",
      type: "line",
      fill: "solid",
      data: algoChartOrganicResults,
    },
  ];

  const bidChartData = [
    {
      name: "Current Bid",
      type: "line",
      fill: "solid",
      data: algoChartCurrentBid,
    },
  ];

  const barChartData = [
    {
      name: "",
      type: "bar",
      fill: "solid",
      data: [
        { x: "Times Analyzed", y: timesAnalyzed },
        {
          x: "Bid Changes",
          y: bidChanges,
          fillColor: "#52CC8B",
          strokeColor: "#52CC8B",
        },
      ],
    },
  ];

  const algoChartOptions = mergeDeep(BaseOptionChart(), {
    plotOptions: {
      bar: { columnWidth: "16%" },
    },
    colors: ["#6A2DFA", "#70C6FD"],
    fill: { type: algoChartData?.map((i) => i.fill) },
    labels: algoChartDateLabels,
    yaxis: [
      {
        seriesName: "Sponsored Rank",
        min: 0,
        max: 30,
        reversed: true,
        labels: {
          formatter: (value) => {
            return value?.toFixed(0);
          },
        },
      },
      {
        seriesName: "Organic Rank",
        min: 0,
        max: 30,
        axisBorder: {
          show: false,
        },
        title: {
          text: "",
        },
        tooltip: {
          enabled: false,
        },
        reversed: true,
        labels: {
          show: false,
          formatter: (value) => {
            return value?.toFixed(0);
          },
        },
      },
    ],
    xaxis: {
      type: "datetime",
      labels: { datetimeUTC: false },
      tickPlacement: "on",
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: [
        {
          formatter: (y) => {
            if (y !== null && y !== undefined) {
              return `${y.toFixed(0)}`;
            }
            return "no result";
          },
        },
        {
          formatter: (y) => {
            if (y !== null && y !== undefined) {
              return `${y.toFixed(0)}`;
            }
            return "no result";
          },
        },
        {
          formatter: (value) => {
            if (value !== null && value !== undefined) {
              return "$" + value.toFixed(2);
            }
            return "no result";
          },
        },
      ],
      x: {
        format: "dd-MMM h:mm tt",
      },
    },
    chart: {
      id: "ranks",
      group: "algorithm",
      animations: {
        enabled: false,
      },
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: true,
        type: "x",
        zoomedArea: {
          fill: {
            color: "#90CAF9",
            opacity: 0.4,
          },
          stroke: {
            color: "#0D47A1",
            opacity: 0.4,
            width: 1,
          },
        },
      },
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
    },
    markers: {
      size: 0,
    },
  });

  const bidChartOptions = mergeDeep(BaseOptionChart(), {
    plotOptions: {
      bar: { columnWidth: "16%" },
    },
    colors: ["#52CC8B"],
    fill: { type: bidChartData?.map((i) => i.fill) },
    labels: algoChartDateLabels,
    yaxis: [
      {
        seriesName: "Current Bid",
        labels: {
          formatter: (value) => {
            return "$" + value?.toFixed(2);
          },
        },
      },
    ],
    xaxis: {
      type: "datetime",
      labels: { datetimeUTC: false },
      tickPlacement: "on",
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: [
        {
          formatter: (value) => {
            if (value !== null && value !== undefined) {
              return "$" + value.toFixed(2);
            }
            return "no result";
          },
        },
      ],
      x: {
        format: "dd-MMM h:mm tt",
      },
    },
    chart: {
      id: "bid",
      group: "algorithm",
      animations: {
        enabled: false,
      },
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: true,
        type: "x",
        zoomedArea: {
          fill: {
            color: "#90CAF9",
            opacity: 0.4,
          },
          stroke: {
            color: "#0D47A1",
            opacity: 0.4,
            width: 1,
          },
        },
      },
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
    },
    markers: {
      size: 0,
    },
  });

  const barChartOptions = mergeDeep(BaseOptionChart(), {
    chart: {
      type: "bar",
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    xaxis: {
      categories: ["Times Analyzed", "Acclio Bid Changes"],
    },
  });

  if (
    statsQuery.isLoading ||
    statsQuery.isIdle ||
    keywordAlgorithmHistoryQuery.isLoading ||
    keywordAlgorithmHistoryQuery.isIdle ||
    keywordAlgorithmHistoryWithAveragesQuery.isLoading ||
    keywordAlgorithmHistoryWithAveragesQuery.isIdle ||
    keywordUserHistoryQuery.isLoading ||
    keywordUserHistoryQuery.isIdle ||
    keywordAmazonReportAggregateQuery.isLoading ||
    keywordAmazonReportAggregateQuery.isIdle
  ) {
    return <Spinner />;
  }

  const enabledProductAd = keyword.apiProductAds?.find(
    (x) => x.state === "ENABLED"
  );

  // show empty chart with message if keyword not selected
  if (!keyword?.id) {
    return (
      <Card>
        <CardHeader
          title="Keyword Results"
          subheader="Select a keyword from the list to view results"
        />
      </Card>
    );
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <KeywordInfo
          keyword={keyword}
          isAdmin={isAdmin}
          crawlMutate={crawlMutate}
          keywordAlgorithmHistoryQuery={keywordAlgorithmHistoryQuery}
          keywordUserHistoryQuery={keywordUserHistoryQuery}
          productGroupId={productGroupId}
          statsQuery={statsQuery}
          navigate={navigate}
          algorithmHistoryWithAverages={algorithmHistoryWithAverages}
          keywordAmazonReport={keywordAmazonReport}
          enabledProductAd={enabledProductAd}
        />
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            title={keyword.searchTerm.term}
            titleTypographyProps={{ variant: "h3" }}
            subheader="Metrics"
            subheaderTypographyProps={{ variant: "body1" }}
            action={
              <Select
                variant="standard"
                value={daysToReturn}
                onChange={handleDaysToReturn}
                sx={{ width: 210 }}
              >
                <MenuItem value={1}>
                  <Typography>1 Day</Typography>
                </MenuItem>
                <MenuItem value={2}>
                  <Typography>2 Days</Typography>
                </MenuItem>
                <MenuItem value={5}>
                  <Typography>5 Days</Typography>
                </MenuItem>
                <MenuItem value={30}>
                  <Typography>30 Days</Typography>
                </MenuItem>
              </Select>
            }
          />
          <Box sx={{ p: 3, pb: 1 }} dir="ltr">
            <ReactApexChart
              type="line"
              series={algoChartData}
              options={algoChartOptions}
              height={300}
              key={keyword.id + "ranks" + Date.now()}
            />
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            title={"Bid Analysis"}
            titleTypographyProps={{ variant: "h3" }}
            subheader="Metrics"
            subheaderTypographyProps={{ variant: "body1" }}
          />
          <Box sx={{ p: 3, pb: 1 }} dir="ltr">
            <ReactApexChart
              type="line"
              series={bidChartData}
              options={bidChartOptions}
              height={300}
              key={keyword.id + "bid" + Date.now()}
            />
          </Box>
          <Box sx={{ p: 3, pb: 1 }} dir="ltr">
            <ReactApexChart
              type="bar"
              series={barChartData}
              options={barChartOptions}
              height={200}
              key={keyword.id + "analysis" + Date.now()}
            />
          </Box>
        </Card>
      </Grid>
      {isAdmin && (
        <Grid item xs={12}>
          <AlgorithmHistoryCard algoData={algorithmHistory} />
        </Grid>
      )}
    </Grid>
  );
}

function AlgorithmHistoryCard(props) {
  const { algoData } = props;
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };

  if (algoData == null) {
    return <Spinner />;
  }
  return (
    <Card>
      <CardHeader
        title={"Algorithm History"}
        titleTypographyProps={{ variant: "h3" }}
        onClick={handleClick}
        action={
          <IconButton>
            <Iconify icon={open ? "mdi:expand-less" : "mdi:expand-more"} />
          </IconButton>
        }
        sx={{ pb: 2, cursor: "pointer" }}
      />
      <Collapse in={open}>
        <KeywordAlgorithmHistoryTable algoData={algoData} />
      </Collapse>
    </Card>
  );
}

function KeywordInfo(props) {
  const {
    keyword,
    isAdmin,
    crawlMutate,
    keywordAlgorithmHistoryQuery,
    keywordUserHistoryQuery,
    productGroupId,
    statsQuery,
    navigate,
    algorithmHistoryWithAverages,
    keywordAmazonReport,
    enabledProductAd,
  } = props;
  return (
    <Stack spacing={1}>
      <Typography variant="body2">Keyword</Typography>
      <Typography variant="h4" style={{ fontSize: "24px" }}>
        {keyword?.searchTerm.term}
      </Typography>
      <Link
        variant="body3"
        href={`${
          keyword.searchTerm.amazonMarketplace.url
        }/s?k=${keyword.searchTerm.term.replaceAll(" ", "+")}`}
        target="_blank"
        rel="noreferrer"
      >
        {`${
          keyword.searchTerm.amazonMarketplace.url
        }/s?k=${keyword.searchTerm.term.replaceAll(" ", "+")}`}
      </Link>
      {isAdmin && (
        <>
          <Link
            variant="body3"
            href={`${config.apiUrl}/test/getLastFailHtml?searchTerm=${keyword.searchTerm.term}`}
            target="_blank"
            rel="noreferrer"
          >
            DEBUG: View Last Fialed Crawl Cache
          </Link>
        </>
      )}
      <Grid
        container
        justifyContent={"flex-start"}
        spacing={2}
        style={{
          marginLeft: "-24px",
        }}
      >
        <Grid item xs={"auto"}>
          <Typography variant="caption" color="text.secondary">
            PPC Rank
          </Typography>
          <Typography variant="body3" style={{ marginTop: "4px" }}>
            {algorithmHistoryWithAverages?.paidRank}
          </Typography>
        </Grid>
        <Grid item xs={"auto"}>
          <Typography variant="caption" color="text.secondary">
            7-Day PPC Rank
          </Typography>
          <Typography variant="body3" style={{ marginTop: "4px" }}>
            {algorithmHistoryWithAverages?.paidRankWeekAverage?.toFixed(3) ||
              "NA"}
          </Typography>
        </Grid>
        <Grid item xs={"auto"}>
          <Typography variant="caption" color="text.secondary">
            Organic Rank
          </Typography>
          <Typography variant="body3" style={{ marginTop: "4px" }}>
            {algorithmHistoryWithAverages?.organicRank}
          </Typography>
        </Grid>
        <Grid item xs={"auto"}>
          <Typography variant="caption" color="text.secondary">
            7-Day Organic Rank
          </Typography>
          <Typography variant="body3" style={{ marginTop: "4px" }}>
            {algorithmHistoryWithAverages?.organicRankWeekAverage?.toFixed(3) ||
              "NA"}
          </Typography>
        </Grid>
        {isAdmin && (
          <Grid item xs={"auto"}>
            <Typography variant="caption" color="text.secondary">
              CTR
            </Typography>
            <Typography variant="body3" style={{ marginTop: "4px" }}>
              {keywordAmazonReport
                ? keywordAmazonReport?.clickThroughRate?.toFixed(2) + "%"
                : "-"}
            </Typography>
          </Grid>
        )}
        {isAdmin && (
          <Grid item xs={"auto"}>
            <Typography variant="caption" color="text.secondary">
              Ad Spend
            </Typography>
            <Typography variant="body3" style={{ marginTop: "4px" }}>
              {keywordAmazonReport ? "$" + keywordAmazonReport.spend : "-"}
            </Typography>
          </Grid>
        )}
        {isAdmin && (
          <Grid item xs={"auto"}>
            <Typography variant="caption" color="text.secondary">
              Cost Per Click
            </Typography>
            <Typography variant="body3" style={{ marginTop: "4px" }}>
              {keywordAmazonReport
                ? "$" + keywordAmazonReport.costPerClick?.toFixed(2)
                : "-"}
            </Typography>
          </Grid>
        )}
        {isAdmin && (
          <Grid item xs={"auto"}>
            <Typography variant="caption" color="text.secondary">
              Clicks
            </Typography>
            <Typography variant="body3" style={{ marginTop: "4px" }}>
              {keywordAmazonReport ? keywordAmazonReport.clicks : "-"}
            </Typography>
          </Grid>
        )}
        {isAdmin && (
          <Grid item xs={"auto"}>
            <Typography variant="caption" color="text.secondary">
              Total Number PPC Units Sold
            </Typography>
            <Typography variant="body3" style={{ marginTop: "4px" }}>
              {keywordAmazonReport ? keywordAmazonReport.unitsSoldClicks : "-"}
            </Typography>
          </Grid>
        )}
        {isAdmin && (
          <Grid item xs={"auto"}>
            <Typography variant="caption" color="text.secondary">
              Conversions
            </Typography>
            <Typography variant="body3" style={{ marginTop: "4px" }}>
              {keywordAmazonReport
                ? (100 * keywordAmazonReport.conversions)?.toFixed(2) + "%"
                : "-"}
            </Typography>
          </Grid>
        )}
        {isAdmin && (
          <Grid item xs={"auto"}>
            <Typography variant="caption" color="text.secondary">
              ACOS
            </Typography>
            <Typography variant="body3" style={{ marginTop: "4px" }}>
              {keywordAmazonReport
                ? (100 * keywordAmazonReport.acos)?.toFixed() + "%"
                : "-"}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid
        container
        justifyContent={"flex-start"}
        spacing={2}
        style={{
          marginBottom: "32px",
          marginLeft: "-24px",
        }}
      >
        <Grid item xs={"auto"}>
          <Typography variant="caption" color="text.secondary">
            Status
          </Typography>
          <Typography variant="body3" style={{ marginTop: "4px" }}>
            {keyword.status || "NA"}
          </Typography>
        </Grid>
        <Grid item xs={"auto"}>
          <Typography variant="caption" color="text.secondary">
            Algorithm State
          </Typography>
          <Typography variant="body3" style={{ marginTop: "4px" }}>
            {keyword.algorithmState || "NA"}
          </Typography>
        </Grid>
        <Grid item xs={"auto"}>
          <Typography variant="caption" color="text.secondary">
            Ad Status
          </Typography>
          <Typography variant="body3" style={{ marginTop: "4px" }}>
            {enabledProductAd?.extendedData?.servingStatus || "NA"}
          </Typography>
        </Grid>
        <Grid item xs={"auto"}>
          <Typography variant="caption" color="text.primary">
            Current Bid <br />
          </Typography>
          <Typography variant="caption" color="text.primary">
            {keyword.status === "Automate"
              ? keyword.currentBid?.toFixed(2)
              : "NA"}
          </Typography>
        </Grid>
        <Grid item xs={"auto"}>
          <Typography variant="caption" color="text.primary">
            Last Bid Analysis
          </Typography>
          {algorithmHistoryWithAverages?.algoRunTime
            ? formatUtcToLocalDateTime(
                algorithmHistoryWithAverages?.algoRunTime
              )
                .split(",")
                .map((place) => (
                  <Typography
                    variant="body3"
                    key={place}
                    sx={{ color: "#52CC8B" }}
                  >
                    {place}
                  </Typography>
                ))
            : ""}
        </Grid>
      </Grid>
      {isAdmin && (
        <Stack
          direction={"row"}
          justifyContent={"flex-start"}
          spacing={3}
          style={{ marginBottom: "32px" }}
        >
          <LoadingButton
            variant="contained"
            loading={
              statsQuery.isLoading ||
              statsQuery.isFetching ||
              keywordAlgorithmHistoryQuery.isLoading ||
              keywordAlgorithmHistoryQuery.isFetching ||
              keywordUserHistoryQuery.isLoading ||
              keywordUserHistoryQuery.isFetching
            }
            onClick={() => {
              statsQuery.refetch();
              keywordAlgorithmHistoryQuery.refetch();
              keywordUserHistoryQuery.refetch();
            }}
          >
            Refresh Metrics
          </LoadingButton>
          <LoadingButton
            variant="outlined"
            loading={crawlMutate.isLoading || crawlMutate.isFetching}
            onClick={() =>
              crawlMutate.mutate({
                productGroupId,
                keywordId: keyword?.id,
              })
            }
          >
            Request Crawl
          </LoadingButton>

          <Button
            variant="outlined"
            onClick={() => navigate(`../keyword/edit/${keyword?.id}`)}
          >
            Edit Keyword
          </Button>
        </Stack>
      )}
    </Stack>
  );
}
