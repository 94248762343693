import { Container, Stack, Tab, Tabs, Typography } from "@mui/material";
import { useParams, useSearchParams } from "react-router-dom";
import Page from "src/components/Page";
import { usePartner } from "src/hooks/partner";
import PartnerUsers from "./PartnerUsers";
import { useSelector } from "react-redux";
import { IsAdmin, IsPartnerAdmin } from "src/slices/authSlice";
import Spinner from "src/components/Spinner";
import EditAccount from "../accounts/EditAccount";
import { LoadingButton } from "@mui/lab";
import { useResyncManagedAccount } from "src/hooks/managedAccount";
import ManagedAccountJobStatus from "../admin/ManagedAccountJobStatus";

export default function PartnerDashboard() {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const isAdmin = useSelector(IsAdmin);
  const resync = useResyncManagedAccount();

  const partnerQuery = usePartner(params.accountId);
  const selectedTab = searchParams.get("tabIndex") || "General";
  const isPartnerAdmin = useSelector(IsPartnerAdmin(params.accountId));

  if (partnerQuery.isLoading) {
    return <Spinner />;
  }

  return (
    <Page
      title={partnerQuery.data?.name}
      breadcrumbs={[
        { label: "Accounts", route: ".." },
        { label: partnerQuery.data?.name },
      ]}
    >
      <Container>
        <Tabs
          sx={{ mb: 1 }}
          value={selectedTab || 0}
          onChange={(_, newValue) => {
            setSearchParams({ tabIndex: newValue });
          }}
        >
          <Tab label="General" value="General" />
          {isPartnerAdmin && <Tab label="Users" value="Users" />}
          {isPartnerAdmin && <Tab label="Settings" value="Settings" />}
          {isAdmin && <Tab label="Job Status" value="Job Status" />}
        </Tabs>
        {selectedTab === "General" && (
          <Stack spacing={1} direction="column" maxWidth="sm">
            <Typography variant="body2">
              We are thrilled to have you onboard for the early preview version
              of our Partner Experience.
            </Typography>
            <Typography variant="body2">
              This platform is designed to allow you to invite additional users.
              As a beta user, your feedback is invaluable to us.
            </Typography>
            {isPartnerAdmin && (
              <Typography variant="body2">
                To manage other users under you partner account - or invite
                additional users to manage your partner account, click the
                "Users" tab above.
              </Typography>
            )}
            {isAdmin && (
              <LoadingButton
                variant="outlined"
                size="small"
                loading={resync.isLoading || resync.isFetching}
                onClick={() => resync.mutate(params.accountId)}
              >
                Resync
              </LoadingButton>
            )}
          </Stack>
        )}
        {selectedTab === "Users" && (
          <PartnerUsers
            partnerId={params.accountId}
            partner={partnerQuery.data}
          />
        )}
        {selectedTab === "Settings" && (
          <EditAccount
            partnerId={params.accountId}
            partner={partnerQuery.data}
          />
        )}
        {selectedTab === "Job Status" && (
          <ManagedAccountJobStatus
            accountId={params.accountId}
            account={params.accountId}
          />
        )}
      </Container>
    </Page>
  );
}
