import {
  TableCell,
  TableRow,
  Toolbar,
  Typography,
  Tooltip,
} from "@mui/material";
import SortableTable from "src/components/SortableTable";
import { useGetHtmlContentLink } from "src/hooks/searchData";
import { formatUtcToLocalDateTime } from "src/utils/formatTime";

const headCells = [
  {
    id: "timestamp",
    numeric: false,
    disablePadding: false,
    label: "Timestamp",
  },
  {
    id: "algoState",
    numeric: false,
    disablePadding: false,
    label: "Algo State",
  },
  {
    id: "ppcRank",
    numeric: true,
    disablePadding: false,
    label: "PPC Rank",
  },
  {
    id: "organicRank",
    numeric: true,
    disablePadding: false,
    label: "Organic Rank",
  },
  {
    id: "originalBid",
    numeric: true,
    disablePadding: false,
    label: "Original Bid",
  },
  {
    id: "newBid",
    numeric: true,
    disablePadding: false,
    label: "New Bid",
  },
  {
    id: "algoAction",
    numeric: false,
    disablePadding: false,
    label: "Algo Action",
  },
];

function createData(data) {
  return {
    id: data.id,
    timestamp: data.algoRunTime || data.searchRankTimestamp,
    algoState: data.algorithmState,
    paidRankTarget: data.paidRankTarget,
    paidRankMax: data.paidRankMax,
    paidRankMin: data.paidRankMin,
    organicRankTarget: data.organicRankTarget,
    organicRankMax: data.organicRankMax,
    organicRankMin: data.organicRankMin,
    ppcRank: data.paidRank,
    organicRank: data.organicRank,
    originalBid: data.originalBid,
    newBid: data.currentBid,
    algoAction: data.algoAction,
    searchId: data.searchId,
  };
}

export default function KeywordAlgorithmHistoryTable({ algoData }) {
  const getLinkMutation = useGetHtmlContentLink();

  const openLinkInNewTab = (searchId) => {
    const openedWindow = window.open("/OpenCachedPage", "_blank");
    getLinkMutation.mutate(
      { searchId },
      {
        onSuccess: (data) => {
          openedWindow.location = data;
        },
      }
    );
  };

  const mapFunction = (row) => (
    <TableRow
      key={row.id}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        {row.timestamp != null ? formatUtcToLocalDateTime(row.timestamp) : "-"}
      </TableCell>
      <TableCell align="left">
        <Tooltip
          title={`Paid Rank Target: ${row.paidRankTarget} 
          Paid Rank Max: ${row.paidRankMax} 
          Paid Rank Min: ${row.paidRankMin} 
          Organic Rank Target: ${row.organicRankTarget} 
          Organic Rank Max: ${row.organicRankMax} 
          Organic Rank Min: ${row.organicRankMin}`}
        >
          <Typography>{row.algoState}</Typography>
        </Tooltip>
      </TableCell>
      <TableCell
        align="right"
        sx={{ cursor: "pointer" }}
        onClick={() => openLinkInNewTab(row.searchId)}
      >
        {row.ppcRank || "Off the page"}
      </TableCell>
      <TableCell align="right">{row.organicRank}</TableCell>
      <TableCell align="right">{row.originalBid?.toFixed(2)}</TableCell>
      <TableCell align="right">{row.newBid?.toFixed(2)}</TableCell>
      <TableCell align="left">{row.algoAction}</TableCell>
    </TableRow>
  );

  if (!algoData) {
    return <></>;
  }

  return (
    <SortableTable
      data={algoData.map((data) => createData(data))}
      initialOrderBy="timestamp"
      initialOrder="desc"
      headCells={headCells}
      mapFunction={mapFunction}
    ></SortableTable>
  );
}
