import { Box } from "@mui/material";

// For adding tabs to Cards, or other components
// From Mui example: https://mui.com/material-ui/react-tabs/#basic-tabs
export default function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
