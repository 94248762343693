import Fab from "@mui/material/Fab";
import React from "react";
import Iconify from "src/components/Iconify";

export default function FabExample() {
  return (
    <div>
      <Fab color="primary" aria-label="add" sx={{ m: 1 }}>
        <Iconify icon="material-symbols:add" />
      </Fab>
      <Fab color="secondary" aria-label="edit" sx={{ m: 1 }}>
        <Iconify icon="material-symbols:edit" />
      </Fab>
      <Fab variant="extended" sx={{ m: 1 }}>
        <Iconify icon="material-symbols:navigation" sx={{ mr: 1 }} />
        Navigate
      </Fab>
      <Fab disabled aria-label="like" sx={{ m: 1 }}>
        <Iconify icon="material-symbols:favorite" />
      </Fab>
    </div>
  );
}
