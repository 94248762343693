import { createSlice } from "@reduxjs/toolkit";
import {
  loadFromSessionStorage,
  saveToSessionStorage,
} from "src/services/sessionStorage";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    selectedManagedAccount:
      loadFromSessionStorage("selectedManagedAccount") || {},
  },
  reducers: {
    setSelectedManagedAccount: (state, { payload }) => {
      state.selectedManagedAccount = payload;
      state.loading = false;
      saveToSessionStorage("selectedManagedAccount", payload);
    },
  },
});

export const { setSelectedManagedAccount } = userSlice.actions;

// Selectors
export const SelectedManagedAccountName = (state) =>
  state.user.selectedManagedAccount?.nickName || "No Account Selected";
export const SelectedManagedAccountId = (state) =>
  state.user.selectedManagedAccount?.id;
export default userSlice.reducer;
