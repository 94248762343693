import {
  useEditManagedAccount,
  useGetManagedAccount,
} from "../../hooks/managedAccount";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";

// material
import {
  Card,
  CardContent,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

// components
import Spinner from "../../components/Spinner";
import { useParams } from "react-router-dom";
import FormikTextField from "src/components/formik/FormikTextField";

export default function EditAccount() {
  const params = useParams();
  const accountId = params.accountId;
  const accountQuery = useGetManagedAccount(accountId);
  const account = accountQuery.data;
  const editAccount = useEditManagedAccount(account?.id);

  const loading = accountQuery.isLoading;

  const schema = Yup.object().shape({
    nickName: Yup.string().required("A nickname for this account is required"),
    externalNamingPrefix: Yup.string().nullable(),
  });

  const formik = useFormik({
    initialValues: {
      nickName: account?.nickName || "",
      externalNamingPrefix: account?.externalNamingPrefix || "Accl.io",
    },
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: (values) => {
      return editAccount.mutate(
        {
          id: account.id,
          nickName: values.nickName,
          externalNamingPrefix: values.externalNamingPrefix,
        },
        {
          onSuccess: () => {
            enqueueSnackbar("Saved Successfully", { variant: "success" });
            formik.setSubmitting(false);
          },
          onError: (error) => {
            enqueueSnackbar(error, { variant: "error" });
          },
        }
      );
    },
  });

  const { values, errors, touched, handleSubmit } = formik;

  const { enqueueSnackbar } = useSnackbar();

  if (loading) {
    return <Spinner />;
  }

  return (
    <Container>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Grid container spacing={3} sx={{ flexGrow: 1 }}>
            <Grid item xs={12}>
              <Typography variant="caption" color="text.secondary">
                Advertising Account:
              </Typography>
              <Typography>
                {account.adAmazonAccountId
                  ? `${account.adAccountName} (Marketplace: ${account.adAccountMarketplace.country}, AccountId: ${account.adAmazonAccountId})`
                  : "You don't seem to have an Advertising Account Connected!"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption" color="text.secondary">
                Seller Account:
              </Typography>
              <Typography>
                {account.spAmazonAccountId
                  ? `${account.spAccountName} (Marketplace: ${account.spAccountMarketplace.country}, AccountId: ${account.spAmazonAccountId})`
                  : "You don't seem to have a Seller Account Connected!"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormikTextField
                fullWidth
                formikName="nickName"
                label="Nickname"
                variant="standard"
                helperText={
                  (touched.nickName && errors.nickName) ||
                  "Used as a friendly display name within this application"
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormikTextField
                fullWidth
                formikName="externalNamingPrefix"
                label="Naming Prefix"
                variant="standard"
                helperText={
                  (touched.externalNamingPrefix &&
                    errors.externalNamingPrefix) ||
                  "This value is used as a prefix when creating Amazon Advertising Portfolios, Campaigns and Ad Groups."
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Typography variant="subtitle1" color="text.secondary">
                    How names will appear in Amazon Advertising
                  </Typography>
                  <Typography variant="subtitle2">Portfolio:</Typography>
                  <Typography variant="body2">
                    {values.externalNamingPrefix || "Accl.io"} Managed Portfolio{" "}
                    <em>&lt;Acclio Account Id&gt; </em>
                  </Typography>
                  <Typography variant="subtitle2">Campaign:</Typography>
                  <Typography variant="body2">
                    {values.externalNamingPrefix || "Accl.io"} Managed Campaign
                    - for &lt;Product Group Short Name&gt;,
                    keyword:&lt;keyword&gt;
                  </Typography>
                  <Typography variant="subtitle2">Ad Group:</Typography>
                  <Typography variant="body2">
                    {values.externalNamingPrefix || "Accl.io"} Managed Ad Group
                    - for &lt;Product Group Short Name&gt;,
                    keyword:&lt;keyword&gt;
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Stack direction={"row"} spacing={3} justifyContent="flex-end">
                <LoadingButton
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={formik.isSubmitting}
                >
                  {`Save`}
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </Container>
  );
}
