// @mui
import { styled } from "@mui/material/styles";
import { Container, Typography } from "@mui/material";

// components
import Page from "../components/Page";
import Logo from "../components/Logo";
import Spinner from "../components/Spinner";

const RootStyle = styled("div")(({ theme }) => ({
  display: "flex",
  height: "100%",
  flexDirection: "column",
  justifyContent: "center",
}));

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  width: "100%",
  display: "flex",
  alignItems: "center",
  position: "relative",
  padding: theme.spacing(3),
  justifyContent: "center",
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(3, 0),
}));

// ----------------------------------------------------------------------

export default function OpenCachedPage() {
  return (
    <Page title="Loading Cached Page">
      <RootStyle>
        <Container maxWidth="sm">
          <HeaderStyle>
            <Logo disabledLink size={100} />
          </HeaderStyle>
          <ContentStyle>
            <Typography variant="h4" align="center" gutterBottom>
              Getting link to s3 for cached page
            </Typography>

            <div align="center">
              <Spinner />
            </div>
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
