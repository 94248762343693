import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import React from "react";
import Iconify from "src/components/Iconify";

export default function BottomNavigationExample() {
  const [value, setValue] = React.useState(0);

  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      showLabels
      sx={{ width: 500 }}
    >
      <BottomNavigationAction
        label="Recents"
        icon={<Iconify icon="material-symbols:restore" />}
      />
      <BottomNavigationAction
        label="Favorites"
        icon={<Iconify icon="material-symbols:favorites" />}
      />
      <BottomNavigationAction
        label="Nearby"
        icon={<Iconify icon="material-symbols:location-on" />}
      />
    </BottomNavigation>
  );
}
