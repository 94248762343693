import { Container } from "@mui/material";
import { useSelector } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import Page from "src/components/Page";
import Spinner from "src/components/Spinner";
import { IsAdmin } from "../../slices/authSlice";
import { SelectedManagedAccountName } from "src/slices/userSlice";
import { useProductGroup } from "src/hooks/productGroup";
import CampaignSalesAttributionReportTable from "./CampaignSalesAndAttributionReportTable";

export default function CampaignSalesAttributionReport() {
  const params = useParams();
  const selectedAccountName = useSelector(SelectedManagedAccountName);
  const productGroupQuery = useProductGroup(params.productGroupId);
  const isAdmin = useSelector(IsAdmin);

  if (!isAdmin) {
    return <Navigate replace to="/portal" />;
  }

  if (productGroupQuery.isLoading) {
    return <Spinner />;
  }

  const productGroup = productGroupQuery.data;
  return (
    <Page
      title={`Campaign Sales Attribution Report: ${productGroup.shortName}`}
      breadcrumbs={[
        { label: "Managed Accounts", route: "/portal/accounts" },
        { label: selectedAccountName, route: "/portal/dashboard" },
        {
          label: productGroup.shortName,
          route: `/portal/dashboard/products/${params.productGroupId}`,
        },
        { label: "Campaign Sales Attribution Report" },
      ]}
    >
      <Container>
        <CampaignSalesAttributionReportTable
          productGroupId={params.productGroupId}
          productGroupName={productGroup.shortName}
        />
      </Container>
    </Page>
  );
}
