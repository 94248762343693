import {
  TableCell,
  TableRow,
  CardHeader,
  Card,
  Box,
  TextField,
  Button,
  Stack,
} from "@mui/material";
import Papa from "papaparse";
import { useState } from "react";
import SortableTable from "src/components/SortableTable";
import { useProfitabilityReport } from "src/hooks/productGroup";
import { formatUTCDateToText } from "src/utils/formatTime";

const headCells = [
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    label: "Date",
  },
  {
    id: "totalGrossSales",
    numeric: true,
    disablePadding: false,
    label: "Total Gross Sales",
  },
  {
    id: "totalUnitsSold",
    numeric: true,
    disablePadding: false,
    label: "Total Units Sold",
  },
  {
    id: "totalAdSpend",
    numeric: true,
    disablePadding: false,
    label: "Total Ad Spend",
  },
  {
    id: "expensePerUnit",
    numeric: true,
    disablePadding: false,
    label: "Expense Per Unit",
  },
  {
    id: "totalExpensesForUnitsSold",
    numeric: true,
    disablePadding: false,
    label: "Total Expenses For Units Sold",
  },
  {
    id: "totalExpenses",
    numeric: true,
    disablePadding: false,
    label: "Total Expenses",
  },
  {
    id: "netProfit",
    numeric: true,
    disablePadding: false,
    label: "Net Profit/Loss",
  },
];

function downloadCSV(array, name) {
  var csv = Papa.unparse(array);

  var csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  var csvURL = null;
  if (navigator.msSaveBlob) {
    csvURL = navigator.msSaveBlob(csvData, "download.csv");
  } else {
    csvURL = window.URL.createObjectURL(csvData);
  }

  var tempLink = document.createElement("a");
  tempLink.href = csvURL;
  tempLink.setAttribute("download", name + "ProfitabilityReport.csv");
  tempLink.click();
}

export default function ProfitabilityReportTable({
  productGroupId,
  productGroupName,
}) {
  const defaultEnd = new Date();
  const defaultStart = new Date(new Date().setDate(defaultEnd.getDate() - 28));
  const [startDate, setStartDate] = useState(defaultStart.toLocaleDateString());
  const [endDate, setEndDate] = useState(defaultEnd.toLocaleDateString());
  const [reportParams, setReportParams] = useState({
    start: defaultStart.toLocaleDateString(),
    end: defaultEnd.toLocaleDateString(),
  });
  const reportQuery = useProfitabilityReport(
    productGroupId,
    reportParams.start,
    reportParams.end
  );
  const report = reportQuery.data;

  const mapFunction = (row) => (
    <TableRow
      key={row.date}
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
      }}
    >
      <TableCell align="left">{formatUTCDateToText(row.date)}</TableCell>
      <TableCell align="right">{"$" + row.totalGrossSales}</TableCell>
      <TableCell align="right">{row.totalUnitsSold}</TableCell>
      <TableCell align="right">{"$" + row.totalAdSpend}</TableCell>
      <TableCell align="right">{"$" + row.expensePerUnit}</TableCell>
      <TableCell align="right">{"$" + row.totalExpensesForUnitsSold}</TableCell>
      <TableCell align="right">{"$" + row.totalExpenses}</TableCell>
      <TableCell align="right">{"$" + row.netProfit}</TableCell>
    </TableRow>
  );

  const updateReport = () => {
    setReportParams({ start: startDate, end: endDate });
  };

  return (
    <Card>
      <CardHeader
        titleTypographyProps={{ variant: "h3" }}
        title="Profitability Report"
        sx={{ pb: 3, pl: 5 }}
      />
      <Stack direction="row" sx={{ pl: 5 }}>
        {" "}
        <TextField
          id="StartDate"
          label="Start Date"
          value={startDate}
          onChange={(event) => setStartDate(event.target.value)}
          sx={{ pr: 3 }}
        />
        <TextField
          id="EndDate"
          label="End Date"
          value={endDate}
          onChange={(event) => setEndDate(event.target.value)}
          sx={{ pr: 3 }}
        />
        <Button sx={{ p: 3 }} onClick={updateReport}>
          Load Data
        </Button>
        <Button
          sx={{ p: 3 }}
          onClick={() => downloadCSV(report, productGroupName)}
        >
          Download CSV
        </Button>
      </Stack>
      <Box sx={{ pl: 3, pr: 6 }}>
        {report !== undefined && (
          <SortableTable
            data={report}
            initialOrderBy="date"
            headCells={headCells}
            mapFunction={mapFunction}
          />
        )}
      </Box>
    </Card>
  );
}
