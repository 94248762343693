import {
  TableCell,
  TableRow,
  CardHeader,
  Card,
  Box,
  TextField,
  Button,
  Stack,
} from "@mui/material";
import Papa from "papaparse";
import { useState } from "react";
import SortableTable from "src/components/SortableTable";
import { useCampaignSalesAttributionReport } from "src/hooks/productGroup";
import { formatUTCDateToText } from "src/utils/formatTime";

const headCells = [
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    label: "Date",
  },
  {
    id: "acclioAttributedSales1d",
    numeric: true,
    disablePadding: false,
    label: "Acclio Attributed Sales 1 Day",
  },
  {
    id: "acclioAttributedSales7d",
    numeric: true,
    disablePadding: false,
    label: "Acclio Attributed Sales 7 Days",
  },
  {
    id: "acclioAttributedSales14d",
    numeric: true,
    disablePadding: false,
    label: "Acclio Attributed Sales 14 Days",
  },
  {
    id: "acclioAttributedSales30d",
    numeric: true,
    disablePadding: false,
    label: "Acclio Attributed Sales 30 Days",
  },
  {
    id: "nonAcclioAttributedSales1d",
    numeric: true,
    disablePadding: false,
    label: "Non-Acclio Attributed Sales 1 Day",
  },
  {
    id: "nonAcclioAttributedSales7d",
    numeric: true,
    disablePadding: false,
    label: "Non-Acclio Attributed Sales 7 Days",
  },
  {
    id: "nonAcclioAttributedSales14d",
    numeric: true,
    disablePadding: false,
    label: "Non-Acclio Attributed Sales 14 Days",
  },
  {
    id: "nonAcclioAttributedSales30d",
    numeric: true,
    disablePadding: false,
    label: "Non-Acclio Attributed Sales 30 Days",
  },
  {
    id: "unitsSold",
    numeric: true,
    disablePadding: false,
    label: "Total Units Sold",
  },
];

function downloadCSV(array, name) {
  var csv = Papa.unparse(array);

  var csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  var csvURL = null;
  if (navigator.msSaveBlob) {
    csvURL = navigator.msSaveBlob(csvData, "download.csv");
  } else {
    csvURL = window.URL.createObjectURL(csvData);
  }

  var tempLink = document.createElement("a");
  tempLink.href = csvURL;
  tempLink.setAttribute(
    "download",
    name + "CampaignSalesAttributionReport.csv"
  );
  tempLink.click();
}

export default function CampaignSalesAttributionReportTable({
  productGroupId,
  productGroupName,
}) {
  const defaultEnd = new Date();
  const defaultStart = new Date(new Date().setDate(defaultEnd.getDate() - 28));
  const [startDate, setStartDate] = useState(defaultStart.toLocaleDateString());
  const [endDate, setEndDate] = useState(defaultEnd.toLocaleDateString());
  const [reportParams, setReportParams] = useState({
    start: defaultStart.toLocaleDateString(),
    end: defaultEnd.toLocaleDateString(),
  });
  const reportQuery = useCampaignSalesAttributionReport(
    productGroupId,
    reportParams.start,
    reportParams.end
  );
  const report = reportQuery.data;

  const mapFunction = (row) => (
    <TableRow
      key={row.date}
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
      }}
    >
      <TableCell align="left">{formatUTCDateToText(row.date)}</TableCell>
      <TableCell align="right">{"$" + row.acclioAttributedSales1d}</TableCell>
      <TableCell align="right">{"$" + row.acclioAttributedSales7d}</TableCell>
      <TableCell align="right">{"$" + row.acclioAttributedSales14d}</TableCell>
      <TableCell align="right">{"$" + row.acclioAttributedSales30d}</TableCell>
      <TableCell align="right">
        {"$" + row.nonAcclioAttributedSales1d}
      </TableCell>
      <TableCell align="right">
        {"$" + row.nonAcclioAttributedSales7d}
      </TableCell>
      <TableCell align="right">
        {"$" + row.nonAcclioAttributedSales14d}
      </TableCell>
      <TableCell align="right">
        {"$" + row.nonAcclioAttributedSales30d}
      </TableCell>
      <TableCell align="right">{row.unitsSold}</TableCell>
    </TableRow>
  );

  const updateReport = () => {
    setReportParams({ start: startDate, end: endDate });
  };

  return (
    <Card>
      <CardHeader
        titleTypographyProps={{ variant: "h3" }}
        title="Campaign Sales and Attribution Report"
        sx={{ pb: 3, pl: 5 }}
      />
      <Stack direction="row" sx={{ pl: 5 }}>
        {" "}
        <TextField
          id="StartDate"
          label="Start Date"
          value={startDate}
          onChange={(event) => setStartDate(event.target.value)}
          sx={{ pr: 3 }}
        />
        <TextField
          id="EndDate"
          label="End Date"
          value={endDate}
          onChange={(event) => setEndDate(event.target.value)}
          sx={{ pr: 3 }}
        />
        <Button sx={{ p: 3 }} onClick={updateReport}>
          Load Data
        </Button>
        <Button
          sx={{ p: 3 }}
          onClick={() => downloadCSV(report, productGroupName)}
        >
          Download CSV
        </Button>
      </Stack>
      <Box sx={{ pl: 3, pr: 6 }}>
        {report !== undefined && (
          <SortableTable
            data={report}
            initialOrderBy="date"
            headCells={headCells}
            mapFunction={mapFunction}
          />
        )}
      </Box>
    </Card>
  );
}
