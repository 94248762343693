import { useMutation, useQuery, useQueryClient } from "react-query";
import api from "../services/api";
import { ObjectToQueryString } from "src/utils/uri";

// API methods - not exposed outside this class
const getDefaultAccount = async () => {
  const { data } = await api.get("/user/defaultAccount");
  return data;
};

const setDefaultAccount = async (user) => {
  const { data } = await api.put(`/user/defaultAccount`, user);
  return data;
};

const getUsers = async (options) => {
  const { data } = await api.get(`/user?${ObjectToQueryString(options)}`);
  return data;
};

const addUserRole = async ({ userId, roleId }) => {
  const { data } = await api.post(`/user/${userId}/role/${roleId}`);
  return data;
};

const removeUserRole = async ({ userId, roleId }) => {
  const { data } = await api.delete(`/user/${userId}/role/${roleId}`);
  return data;
};

// Custom hooks
export const useUserDefaultAccount = () => {
  return useQuery(
    "userDefaultManagedAccount",
    () => {
      return getDefaultAccount();
    },
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    }
  );
};

export const useSetDefaultAccount = (user) => {
  const queryClient = useQueryClient();

  return useMutation(setDefaultAccount, {
    onSuccess: () => {
      queryClient.invalidateQueries("userDefaultManagedAccount");
    },
  });
};

export const useUserList = (page = 0, perPage = 10) => {
  return useQuery(["userList", { page, perPage }], () =>
    getUsers({ page, perPage })
  );
};

export const useAddUserRole = () => {
  const queryClient = useQueryClient();

  return useMutation(addUserRole, {
    onSuccess: () => {
      return queryClient.invalidateQueries(["userList"]);
    },
  });
};

export const useRemoveUserRole = () => {
  const queryClient = useQueryClient();

  return useMutation(removeUserRole, {
    onSuccess: () => {
      return queryClient.invalidateQueries(["userList"]);
    },
  });
};
