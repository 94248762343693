import { Card, CardHeader, Container, Tab, Tabs } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import Page from "src/components/Page";
import Spinner from "src/components/Spinner";
import { IsAdmin } from "../../slices/authSlice";
import JobStatusTable from "./JobStatusTable";
import { useGetJobDataForProductGroup } from "src/hooks/admin";
import { useProductGroup } from "src/hooks/productGroup";
import {
  SelectedManagedAccountId,
  SelectedManagedAccountName,
  setSelectedManagedAccount,
} from "src/slices/userSlice";
import { useEffect, useState } from "react";
import { useGetManagedAccount } from "src/hooks/managedAccount";
import CustomTabPanel from "src/components/CustomTabPanel";

export default function ProductGroupJobStatus() {
  const params = useParams();
  const isAdmin = useSelector(IsAdmin);
  const accountQuery = useGetManagedAccount(params.accountId);
  const account = accountQuery.data;
  const dispatch = useDispatch();
  const selectedAccountName = useSelector(SelectedManagedAccountName);
  const selectedAccountId = useSelector(SelectedManagedAccountId);
  const productGroupQuery = useProductGroup(params.productGroupId);
  const productGroup = productGroupQuery.data;
  const jobDataQuery = useGetJobDataForProductGroup(params.productGroupId);

  const [tab, setTab] = useState(0);
  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  // So that site behaves as expected if url manually navigated to
  useEffect(() => {
    if (
      (!accountQuery.isLoading || !accountQuery.isIdle) &&
      selectedAccountId !== params.accountId
    ) {
      dispatch(setSelectedManagedAccount(account));
    }
  }, [selectedAccountId, params, dispatch, account, accountQuery]);

  if (!isAdmin) {
    return <Navigate replace to="/portal" />;
  }

  if (
    jobDataQuery.isLoading ||
    jobDataQuery.isIdle ||
    productGroupQuery.isLoading ||
    productGroupQuery.isIdle
  ) {
    return <Spinner />;
  }

  const jobData = jobDataQuery.data;
  const keywordData = jobData.filter((item) => {
    return item.jobTypeId === 3;
  });
  const crawlData = jobData.filter((item) => {
    return item.jobTypeId === 1;
  });
  const broadAndPhraseSyncData = jobData.filter((item) => {
    return item.jobTypeId === 7;
  });

  return (
    <Page
      title={`Job Status: ${productGroup.shortName}`}
      breadcrumbs={[
        { label: "Admin", route: "/portal/admin" },
        { label: "Managed Accounts", route: "/portal/accounts" },
        { label: selectedAccountName, route: "/portal/dashboard" },
        {
          label: productGroup.shortName,
          route: `/portal/dashboard/products/${params.productGroupId}`,
        },
        { label: "Job Status" },
      ]}
    >
      <Container maxWidth={"false"}>
        <Card>
          <CardHeader
            titleTypographyProps={{ variant: "h3" }}
            title="Job Status"
            sx={{ pb: 1.5, pl: 6 }}
            action={
              <Tabs value={tab} onChange={handleChange}>
                <Tab label="Keyword Automation" id={0} />
                <Tab label="Crawl" id={1} />
                <Tab label="Broad And Phrase Campaign Sync" id={1} />
              </Tabs>
            }
          />
          <CustomTabPanel value={tab} index={0}>
            <JobStatusTable data={keywordData} />
          </CustomTabPanel>
          <CustomTabPanel value={tab} index={1}>
            <JobStatusTable data={crawlData} />
          </CustomTabPanel>
          <CustomTabPanel value={tab} index={2}>
            <JobStatusTable data={broadAndPhraseSyncData} />
          </CustomTabPanel>
        </Card>
      </Container>
    </Page>
  );
}
