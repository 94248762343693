import api from "./api";
import TokenService from "./token.service";

const register = (email, firstName, lastName, password, inviteToken) => {
  return api
    .post("/auth/register", {
      email,
      firstName,
      lastName,
      password,
      inviteToken,
    })
    .then((response) => {
      if (response.data.refreshToken) {
        TokenService.setRefreshToken(response.data.refreshToken);
      } else {
        TokenService.removeRefreshToken();
      }
      return response.data;
    });
};

const login = (email, password, inviteToken) => {
  return api
    .post("/auth/signOn", {
      email,
      password,
      inviteToken,
    })
    .then((response) => {
      if (response.data.refreshToken) {
        TokenService.setRefreshToken(response.data.refreshToken);
      } else {
        TokenService.removeRefreshToken();
      }
      return response.data;
    });
};

const verify = (email, token) => {
  return api
    .post("/auth/verify", {
      email,
      token,
    })
    .then((response) => {
      return response.data;
    });
};

const resendVerification = (email) => {
  return api
    .post("/auth/resendVerification", {
      email,
    })
    .then((response) => {
      return response.data;
    });
};

const sendPasswordReset = (email) => {
  return api
    .post("/auth/sendPasswordReset", {
      email,
    })
    .then((response) => {
      return response.data;
    });
};

const passwordResetWithToken = (email, token, password) => {
  return api
    .post("/auth/passwordResetWithToken", {
      email,
      token,
      password,
    })
    .then((response) => {
      return response.data;
    });
};

const logout = () => {
  // TODO: This needs to be updated once a refresh token revoke method
  //       is available on masterdataservice
  TokenService.removeRefreshToken();
};

const tryRefresh = () => {
  const refreshToken = TokenService.getRefreshToken();
  if (refreshToken) {
    return api
      .post("/auth/refresh", {
        refreshToken,
      })
      .then((response) => {
        if (response.data.refreshToken) {
          TokenService.setRefreshToken(response.data.refreshToken);
        } else {
          TokenService.removeRefreshToken();
        }
        return response.data;
      })
      .catch((err) => {
        TokenService.removeRefreshToken();
        throw err;
      });
  }
  return Promise.resolve;
};

const AuthService = {
  register,
  resendVerification,
  login,
  logout,
  tryRefresh,
  verify,
  sendPasswordReset,
  passwordResetWithToken,
};
export default AuthService;
