import Fab from "@mui/material/Fab";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import React from "react";
import Iconify from "src/components/Iconify";

export default function TooltipExample() {
  return (
    <div>
      <Tooltip title="Delete">
        <IconButton aria-label="delete" size="large">
          <Iconify icon="material-symbols:delete" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Add" aria-label="add">
        <Fab color="primary" sx={{ m: 2 }}>
          <Iconify icon="material-symbols:add" />
        </Fab>
      </Tooltip>
      <Tooltip title="Add" aria-label="add">
        <Fab color="secondary" sx={{ m: 2 }}>
          <Iconify icon="material-symbols:add" />
        </Fab>
      </Tooltip>
    </div>
  );
}
