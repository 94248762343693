import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { useSnackbar } from "notistack";
import { useInvitePartnerUser } from "src/hooks/partner";
import InviteUserForm from "./InviteUserForm";

export const InviteUserModal = ({ partnerId, roles = [], open, close }) => {
  const inviteUser = useInvitePartnerUser();
  const { enqueueSnackbar } = useSnackbar();

  const handleSave = async (invite) => {
    try {
      await inviteUser.mutateAsync({ partnerId, invite });
      close();
      enqueueSnackbar("Invited User", {
        variant: "success",
        autoHideDuration: 2000,
      });
    } catch (error) {
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  };

  return (
    <Dialog open={open} onClose={close} fullWidth={true} maxWidth="md">
      <DialogTitle>Invite User by email</DialogTitle>
      <DialogContent>
        <InviteUserForm roles={roles} onCancel={close} onSave={handleSave} />
      </DialogContent>
    </Dialog>
  );
};
