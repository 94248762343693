import { Button, MenuItem, Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import HookFormTextField from "src/components/HookFormTextField";

const schema = Yup.object().shape({
  email: Yup.string()
    .email("Email must be a valid email address")
    .required("Email is required"),
  partnerRoleId: Yup.number().required("A role must be selected"),
});

export default function InviteUserForm({ roles = [], onSave, onCancel }) {
  const formMethods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    formState,
    formState: { isSubmitting },
  } = formMethods;

  return (
    <form onSubmit={handleSubmit(onSave)} autoComplete="off">
      <Stack direction="column" spacing={2} sx={{ minWidth: "300px", mt: 1 }}>
        <HookFormTextField
          name="email"
          label="Email Address"
          fullWidth
          formMethods={formMethods}
        />
        <HookFormTextField
          select
          name="partnerRoleId"
          label="Role"
          fullWidth
          formMethods={formMethods}
        >
          {roles.map((role) => (
            <MenuItem key={role.id} value={role.id}>
              {role.name}
            </MenuItem>
          ))}
        </HookFormTextField>

        <Stack direction="row" spacing={2} justifyContent="center">
          <Button color="primary" variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
            disabled={!formState.isValid && formState.submitCount > 0}
          >
            Invite
          </LoadingButton>
        </Stack>
      </Stack>
    </form>
  );
}
