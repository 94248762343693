import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import TokenService from "../services/token.service";
import { refreshAsync } from "../slices/authSlice";
import Spinner from "./Spinner";

// ----------------------------------------------------------------------

export default function AutoLogon({ children }) {
  const { authenticated } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const refreshTokenPresent = !!TokenService.getRefreshToken();

  // TODO: need some listener here that will listen for localStorage update to "refreshToken"
  //       when it becomes empty (or maybe listen for logout post message event) then we should trigger a
  //       logout

  useEffect(() => {
    if (!authenticated && refreshTokenPresent) {
      dispatch(refreshAsync());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!authenticated && refreshTokenPresent) {
    return <Spinner />;
  } else {
    return children;
  }
}
