import { useQuery, useMutation, useQueryClient } from "react-query";
import api from "../services/api";

// API methods - not exposed outside this class
const getFromAccount = async (managedAccountId, filter) => {
  const { data } = await api.get(
    `/productGroup?managedAccountId=${managedAccountId}${
      filter ? "&filter=" + filter : ""
    }`
  );
  return data;
};

const get = async (id) => {
  const { data } = await api.get(`/productGroup/${id}`);
  return data;
};

const update = async (productGroup) => {
  const { data } = await api.put(
    `/productGroup/${productGroup.id}`,
    productGroup
  );
  return data;
};

const getProductGroupKeywords = async (productGroupId) => {
  const { data } = await api.get(`/productGroup/${productGroupId}/keyword`);
  return data;
};

const getProductGroupKeyword = async (productGroupId, keywordId) => {
  const { data } = await api.get(
    `productGroup/${productGroupId}/keyword/${keywordId}`
  );
  return data;
};

const addKeyword = async ({ productGroupId, payload }) => {
  const { data } = await api.post(
    `/productGroup/${productGroupId}/keyword`,
    payload
  );
  return data;
};

const addBroadAndPhraseKeywords = async ({
  productGroupId,
  payload,
  initialBid = null,
}) => {
  const { data } = await api.post(
    `/productGroup/${productGroupId}/keyword/broadAndPhrase` +
      (initialBid ? `?initialBid=${initialBid}` : ""),
    payload
  );
  return data;
};

const updateKeyword = async ({ productGroupId, keyword }) => {
  const { data } = await api.put(
    `/productGroup/${productGroupId}/keyword/${keyword.id}`,
    keyword
  );
  return data;
};

const getKeywordStatistics = async (productGroupId, keywordId) => {
  if (!productGroupId || !keywordId) {
    return {};
  }
  const { data } = await api.get(
    `/productGroup/${productGroupId}/keyword/${keywordId}/searchStatistics`
  );
  return data;
};

const getKeywordAlgorithmHistory = async (
  productGroupId,
  keywordId,
  daysToReturn
) => {
  if (!productGroupId || !keywordId) {
    return {};
  }
  const { data } = await api.get(
    `/productGroup/${productGroupId}/keyword/${keywordId}/algorithmHistory?daysToReturn=${daysToReturn}`
  );
  return data;
};

const getKeywordUserHistory = async (productGroupId, keywordId) => {
  if (!productGroupId || !keywordId) {
    return {};
  }
  const { data } = await api.get(
    `/productGroup/${productGroupId}/keyword/${keywordId}/userHistory`
  );
  return data;
};

const getProductGroupKeywordsAlgorithmHistory = async (productGroupId) => {
  if (!productGroupId) {
    return {};
  }
  const { data } = await api.get(
    `/productGroup/${productGroupId}/keyword/algorithmHistory`
  );
  return data;
};

const getProductGroupKeywordAlgorithmHistory = async (
  productGroupId,
  keywordId
) => {
  if (!productGroupId) {
    return {};
  }
  const { data } = await api.get(
    `/productGroup/${productGroupId}/keyword/algorithmHistory?keywordId=${keywordId}`
  );
  return data;
};

const getProductGroupKeywordsUserHistory = async (productGroupId) => {
  if (!productGroupId) {
    return {};
  }
  const { data } = await api.get(
    `/productGroup/${productGroupId}/keyword/userHistory`
  );
  return data;
};

const crawlKeyword = async ({ productGroupId, keywordId }) => {
  const { data } = await api.post(
    `/productGroup/${productGroupId}/keyword/${keywordId}/crawl`
  );
  return data;
};

const getKeywordSummariesFromAccount = async (managedAccountId) => {
  const { data } = await api.get(
    `/productGroup/keyword/summary?managedAccountId=${managedAccountId}`
  );
  return data;
};

const getKeywordAmazonReports = async (productGroupId) => {
  const { data } = await api.get(
    `/productGroup/${productGroupId}/campaignReport`
  );
  return data;
};

const getKeywordAmazonReport = async (productGroupId, keywordId) => {
  const { data } = await api.get(
    `/productGroup/${productGroupId}/campaignReport/keyword/${keywordId}`
  );
  return data;
};

const getKeywordAmazonReportAggregate = async (productGroupId, keywordId) => {
  const { data } = await api.get(
    `/productGroup/${productGroupId}/campaignReport/keyword/${keywordId}/aggregate`
  );
  return data;
};

const getProfitabilityReport = async (productGroupId, startDate, endDate) => {
  const { data } = await api.get(
    `/admin/productGroup/${productGroupId}/profitabilityReport?startDateString=${startDate}&endDateString=${endDate}`
  );
  return data;
};

const getProfitabilityReport2 = async (productGroupId, startDate, endDate) => {
  const { data } = await api.get(
    `/admin/productGroup/${productGroupId}/profitabilityReport2?startDateString=${startDate}&endDateString=${endDate}`
  );
  return data;
};

const getCampaignSalesAttributionReport = async (
  productGroupId,
  startDate,
  endDate
) => {
  const { data } = await api.get(
    `/admin/productGroup/${productGroupId}/campaignSalesAttributionReport?startDateString=${startDate}&endDateString=${endDate}`
  );
  return data;
};

const updateBroadAndPhraseSettings = async (productGroup) => {
  const { data } = await api.put(
    `/productGroup/${productGroup.id}/broadAndPhraseSettings`,
    productGroup
  );
  return data;
};

const getExactMatchTableData = async (productGroupId) => {
  const { data } = await api.get(
    `/productGroup/${productGroupId}/exactMatchTable/user`
  );
  return data;
};

const getExactMatchTableAdminData = async (productGroupId) => {
  const { data } = await api.get(
    `/productGroup/${productGroupId}/exactMatchTable/admin`
  );
  return data;
};

const getBroadAndPhraseTableData = async (productGroupId) => {
  const { data } = await api.get(
    `/productGroup/${productGroupId}/broadAndPhraseTable/user`
  );
  return data;
};

const getAllKeywordsTableData = async (productGroupId) => {
  const { data } = await api.get(
    `/productGroup/${productGroupId}/allKeywordsTable/user`
  );
  return data;
};

const getAmazonRecommendedKeywords = async ({ productGroupId, payload }) => {
  const { data } = await api.post(
    `/productGroup/${productGroupId}/KeywordRecommendations`,
    payload
  );
  return data;
};

const updateProductGroupDefaultAdvertisedSkus = async ({
  productGroupId,
  payload,
}) => {
  const { data } = await api.post(
    `/productGroup/${productGroupId}/defaultAdvertisedSkus`,
    payload
  );
  return data;
};

const updateKeywordAdvertisedSkus = async ({
  productGroupId,
  keywordId,
  payload,
  useProductGroupDefault,
}) => {
  const { data } = await api.post(
    `/productGroup/${productGroupId}/keyword/${keywordId}/advertisedSkus` +
      (useProductGroupDefault !== null
        ? `?useProductGroupDefault=${useProductGroupDefault}`
        : ""),
    payload
  );
  return data;
};

// Custom hooks
export const useProductGroups = (managedAccountId, filter) => {
  return useQuery(
    ["ProductGroups", managedAccountId, filter],
    () => {
      return getFromAccount(managedAccountId, filter);
    },
    { enabled: !!managedAccountId }
  );
};

export const useProductGroup = (productGroupId) => {
  return useQuery(`ProductGroup~${productGroupId}`, () => {
    return get(productGroupId);
  });
};

export const useUpdateProductGroup = (productGroupId) => {
  const queryClient = useQueryClient();

  return useMutation(update, {
    onSuccess: () => {
      queryClient.invalidateQueries(`ProductGroup~${productGroupId}`);
      queryClient.invalidateQueries(`ProductGroups`);
    },
  });
};

export const useUpdateBroadAndPhraseSettings = (productGroupId) => {
  const queryClient = useQueryClient();

  return useMutation(updateBroadAndPhraseSettings, {
    onSuccess: () => {
      queryClient.invalidateQueries(`ProductGroup~${productGroupId}`);
      queryClient.invalidateQueries(`ProductGroups`);
    },
  });
};

export const useProductGroupKeywords = (productGroupId) => {
  return useQuery(`ProductGroupKeywords~${productGroupId}`, () => {
    return getProductGroupKeywords(productGroupId);
  });
};

export const useProductGroupKeyword = (productGroupId, keywordId) => {
  return useQuery(
    `ProductGroupKeyword~${productGroupId}~keyword~${keywordId}`,
    () => {
      return getProductGroupKeyword(productGroupId, keywordId);
    },
    { enabled: !!productGroupId }
  );
};

export const useAddProductGroupKeyword = (productGroupId) => {
  const queryClient = useQueryClient();

  return useMutation(addKeyword, {
    onSuccess: () => {
      queryClient.invalidateQueries(`ProductGroupKeywords~${productGroupId}`);
      queryClient.invalidateQueries(
        `ExactMatchTableAdminData~${productGroupId}`
      );
      queryClient.invalidateQueries(`ExactMatchTableData~${productGroupId}`);
      queryClient.invalidateQueries(`AllKeywordsTableData~${productGroupId}`);
    },
  });
};

export const useAddBroadAndPhraseKeywords = (productGroupId) => {
  const queryClient = useQueryClient();

  return useMutation(addBroadAndPhraseKeywords, {
    onSuccess: () => {
      queryClient.invalidateQueries(`ProductGroupKeywords~${productGroupId}`);
      queryClient.invalidateQueries({
        queryKey: [`BroadAndPhraseTableData`, productGroupId],
      });
      queryClient.invalidateQueries({
        queryKey: [`AllKeywordsTableData`, productGroupId],
      });
    },
  });
};

export const useUpdateProductGroupKeyword = (productGroupId, keywordId) => {
  const queryClient = useQueryClient();

  return useMutation(updateKeyword, {
    onSuccess: () => {
      queryClient.invalidateQueries(`ProductGroupKeywords~${productGroupId}`);
      queryClient.invalidateQueries(
        `ProductGroupKeyword~${productGroupId}~keyword~${keywordId}`
      );
      queryClient.invalidateQueries({
        queryKey: [`ExactMatchTableAdminData`, productGroupId],
      });
      queryClient.invalidateQueries({
        queryKey: [`ExactMatchTableData`, productGroupId],
      });
      queryClient.invalidateQueries({
        queryKey: [`BroadAndPhraseTableData`, productGroupId],
      });
      queryClient.invalidateQueries({
        queryKey: [`AllKeywordsTableData`, productGroupId],
      });
    },
  });
};

export const useGroupKeywordStatisticsQuery = ({
  productGroupId,
  keywordId,
}) => {
  return useQuery(
    ["ProductGroupKeywordStatistics", productGroupId, keywordId],
    () => {
      return getKeywordStatistics(productGroupId, keywordId);
    }
  );
};

export const useGetKeywordAlgorithmHistory = ({
  productGroupId,
  keywordId,
  daysToReturn,
}) => {
  return useQuery(
    [
      "ProductGroupKeywordAlgorithmHistory",
      productGroupId,
      keywordId,
      daysToReturn,
    ],
    () => {
      return getKeywordAlgorithmHistory(
        productGroupId,
        keywordId,
        daysToReturn
      );
    }
  );
};

export const useGetKeywordUserHistory = ({ productGroupId, keywordId }) => {
  return useQuery(
    ["ProductGroupKeywordUserHistory", productGroupId, keywordId],
    () => {
      return getKeywordUserHistory(productGroupId, keywordId);
    }
  );
};

export const useGetProductGroupKeywordsAlgorithmHistory = ({
  productGroupId,
}) => {
  return useQuery(
    ["ProductGroupKeywordsAlgorithmHistory", productGroupId],
    () => {
      return getProductGroupKeywordsAlgorithmHistory(productGroupId);
    }
  );
};

export const useGetProductGroupKeywordAlgorithmHistory = ({
  productGroupId,
  keywordId,
}) => {
  return useQuery(
    ["ProductGroupKeywordsAlgorithmHistory", productGroupId, keywordId],
    () => {
      return getProductGroupKeywordAlgorithmHistory(productGroupId, keywordId);
    }
  );
};

export const useGetProductGroupKeywordsUserHistory = ({ productGroupId }) => {
  return useQuery(["ProductGroupKeywordsUserHistory", productGroupId], () => {
    return getProductGroupKeywordsUserHistory(productGroupId);
  });
};

export const useCrawlProductGroupKeyword = () => {
  return useMutation(crawlKeyword);
};

export const useKeywordSummaries = (managedAccountId) => {
  return useQuery(
    "KeywordSummaries",
    () => {
      return getKeywordSummariesFromAccount(managedAccountId);
    },
    { enabled: !!managedAccountId }
  );
};

export const useKeywordAmazonReports = (productGroupId) => {
  return useQuery(
    ["KeywordAmazonReports", productGroupId],
    () => {
      return getKeywordAmazonReports(productGroupId);
    },
    { enabled: !!productGroupId }
  );
};

export const useKeywordAmazonReport = (productGroupId, keywordId) => {
  return useQuery(
    ["KeywordAmazonReport", productGroupId, keywordId],
    () => {
      return getKeywordAmazonReport(productGroupId, keywordId);
    },
    { enabled: !!productGroupId || !!keywordId }
  );
};

export const useKeywordAmazonReportAggregate = (productGroupId, keywordId) => {
  return useQuery(
    ["KeywordAmazonReportAggregate", productGroupId, keywordId],
    () => {
      return getKeywordAmazonReportAggregate(productGroupId, keywordId);
    },
    { enabled: !!productGroupId || !!keywordId }
  );
};

export const useProfitabilityReport = (productGroupId, startDate, endDate) => {
  return useQuery(
    ["ProfitabilityReport", productGroupId, startDate, endDate],
    () => {
      return getProfitabilityReport(productGroupId, startDate, endDate);
    }
  );
};
export const useProfitabilityReport2 = (productGroupId, startDate, endDate) => {
  return useQuery(
    ["ProfitabilityReport2", productGroupId, startDate, endDate],
    () => {
      return getProfitabilityReport2(productGroupId, startDate, endDate);
    }
  );
};

export const useCampaignSalesAttributionReport = (
  productGroupId,
  startDate,
  endDate
) => {
  return useQuery(
    ["CampaignSalesAttributionReport", productGroupId, startDate, endDate],
    () => {
      return getCampaignSalesAttributionReport(
        productGroupId,
        startDate,
        endDate
      );
    }
  );
};

export const useExactMatchTableData = (productGroupId) => {
  return useQuery(["ExactMatchTableData", productGroupId], () => {
    return getExactMatchTableData(productGroupId);
  });
};

export const useExactMatchTableAdminData = (productGroupId) => {
  return useQuery(["ExactMatchTableAdminData", productGroupId], () => {
    return getExactMatchTableAdminData(productGroupId);
  });
};

export const useBroadAndPhraseTableData = (productGroupId) => {
  return useQuery(["BroadAndPhraseTableData", productGroupId], () => {
    return getBroadAndPhraseTableData(productGroupId);
  });
};

export const useAllKeywordsTableData = (productGroupId) => {
  return useQuery(["AllKeywordsTableData", productGroupId], () => {
    return getAllKeywordsTableData(productGroupId);
  });
};

export const useGetAmazonRecommendedKeywords = () => {
  return useMutation(getAmazonRecommendedKeywords);
};

export const useUpdateProductGroupDefaultAdvertisedSkus = (productGroupId) => {
  const queryClient = useQueryClient();
  return useMutation(updateProductGroupDefaultAdvertisedSkus, {
    onSuccess: () => {
      queryClient.invalidateQueries(`ProductGroup~${productGroupId}`);
      queryClient.invalidateQueries(`ProductGroups`);
    },
  });
};

export const useUpdateKeywordAdvertisedSkus = (productGroupId, keywordId) => {
  const queryClient = useQueryClient();
  return useMutation(updateKeywordAdvertisedSkus, {
    onSuccess: () => {
      queryClient.invalidateQueries(
        `ProductGroupKeyword~${productGroupId}~keyword~${keywordId}`
      );
      queryClient.invalidateQueries(`ProductGroupKeywords~${productGroupId}`);
    },
  });
};
