import { LoadingButton } from "@mui/lab";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
} from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import Page from "src/components/Page";
import Spinner from "src/components/Spinner";
import FormikTextField from "src/components/formik/FormikTextField";
import {
  useProductGroup,
  useUpdateBroadAndPhraseSettings,
} from "src/hooks/productGroup";
import { IsAdmin } from "src/slices/authSlice";
import { SelectedManagedAccountName } from "src/slices/userSlice";
import * as Yup from "yup";

const schema = Yup.object().shape({
  defaultBroadAndPhraseConversion: Yup.number()
    .typeError(
      "Default Broad and Phrase Conversion must be a number between 0 and 1"
    )
    .moreThan(0, "Default Broad and Phrase Conversion must be more than 0")
    .max(1, "Default Broad and Phrase Conversion must be 1 or less"),
  defaultBroadAndPhraseMaxBid: Yup.number()
    .typeError("Default Broad and Phrase Max Bid must be a positive number")
    .min(0, "Default Broad and Phrase Max Bid can't be negative"),
  broadAndPhraseBudget: Yup.number()
    .typeError("Broad and Phrase Budget must be as positive number")
    .min(0, "Broad and Phrase Budget can't be negative"),
  broadAndPhraseEnabled: Yup.bool().typeError(
    "Broad and Phrase Enabled must be True or False"
  ),
});

export default function BroadAndPhraseSettings() {
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const selectedAccountName = useSelector(SelectedManagedAccountName);
  const productGroupQuery = useProductGroup(params.productGroupId);

  const productGroup = productGroupQuery.data;
  const updateBroadAndPhraseSettings = useUpdateBroadAndPhraseSettings(
    params.productGroupId
  );

  const formik = useFormik({
    initialValues: {
      defaultBroadAndPhraseConversion:
        productGroup?.defaultBroadAndPhraseConversion || 0.5,
      defaultBroadAndPhraseMaxBid:
        productGroup?.defaultBroadAndPhraseMaxBid || 0,
      broadAndPhraseBudget: productGroup?.broadAndPhraseBudget || 0,
      broadAndPhraseEnabled: productGroup?.broadAndPhraseEnabled || false,
    },
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: (values, { setSubmitting }) => {
      return updateBroadAndPhraseSettings.mutate(
        {
          id: productGroup.id,
          ...values,
        },
        {
          onSuccess: () => {
            enqueueSnackbar("Successfully Saved", { variant: "success" });
            navigate(-1);
          },
          onError: (error) => {
            enqueueSnackbar(error, { variant: "error" });
            setSubmitting(false);
          },
        }
      );
    },
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    getFieldProps,
    setFieldValue,
  } = formik;

  const isAdmin = useSelector(IsAdmin);

  if (!isAdmin) {
    return <Navigate replace to="/portal" />;
  }

  if (productGroupQuery.isLoading) {
    return <Spinner />;
  }

  return (
    <Page
      title={`Broad and Phrase Settings: ${productGroup.shortName}`}
      breadcrumbs={[
        { label: "Managed Accounts", route: "/portal/accounts" },
        { label: selectedAccountName, route: "/portal/dashboard" },
        {
          label: productGroup.shortName,
          route: `/portal/dashboard/products/${params.productGroupId}`,
        },
        { label: "Broad and Phrase Settings" },
      ]}
    >
      <Container fixed maxWidth={"xl"}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card>
                  <CardHeader title="Broad and Phrase Campaign Settings" />
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <FormikTextField
                          fullWidth
                          formikName="broadAndPhraseBudget"
                          label="Broad and Phrase Budget"
                          variant="standard"
                          helperText={
                            touched.broadAndPhraseBudget &&
                            errors.broadAndPhraseBudget
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormikTextField
                          fullWidth
                          formikName="defaultBroadAndPhraseMaxBid"
                          label="Default Max Bid"
                          variant="standard"
                          helperText={
                            touched.defaultBroadAndPhraseMaxBid &&
                            errors.defaultBroadAndPhraseMaxBid
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormikTextField
                          fullWidth
                          formikName="defaultBroadAndPhraseConversion"
                          label="Default Conversion"
                          variant="standard"
                          helperText={
                            touched.defaultBroadAndPhraseConversion &&
                            errors.defaultBroadAndPhraseConversion
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              id="broadAndPhraseEnabled"
                              checked={values.broadAndPhraseEnabled}
                              onChange={handleChange}
                            />
                          }
                          label="Broad and Phrase Enabled"
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardActions>
                    <Grid container spacing={3} justifyContent="flex-end">
                      <Grid item>
                        <Button
                          size="large"
                          variant="text"
                          color="error"
                          onClick={() => navigate(-1)}
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item>
                        <LoadingButton
                          size="large"
                          type="button"
                          variant="contained"
                          loading={formik.isSubmitting}
                          onClick={handleSubmit}
                        >
                          {`Save`}
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </Container>
    </Page>
  );
}
