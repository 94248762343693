import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// material
import {
  Card,
  Typography,
  Grid,
  CardContent,
  CardActions,
  Button,
  CardActionArea,
  Divider,
} from "@mui/material";

// components
import { setSelectedManagedAccount } from "src/slices/userSlice";
import { useSetDefaultAccount, useUserDefaultAccount } from "src/hooks/user";
import { useResyncManagedAccount } from "src/hooks/managedAccount";
import { LoadingButton } from "@mui/lab";
import { formatUtcToLocalDateTime } from "src/utils/formatTime";
import Iconify from "src/components/Iconify";
import { IsPartnerAdmin } from "src/slices/authSlice";

export default function AccountCard({ account, admin = false }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isPartnerAdmin = useSelector(IsPartnerAdmin(account.id));
  const defaultAccountData = useUserDefaultAccount();
  const defaultAccount = defaultAccountData.data;
  const setDefaultAccount = useSetDefaultAccount();
  const resync = useResyncManagedAccount();
  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card>
        <CardActionArea
          onClick={() => {
            dispatch(setSelectedManagedAccount(account || {}));
            navigate("/portal/dashboard");
          }}
        >
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h4">{account.nickName}</Typography>
                <Divider
                  sx={{
                    borderColor: "rgba(0, 0, 0, 0.2)",
                    marginTop: "4px",
                    marginBottom: "16px",
                  }}
                />
              </Grid>
              {admin && (
                <Grid item xs={12}>
                  <Typography variant="caption">Owner</Typography>
                  <Typography
                    variant="body3"
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: "2",
                      WebkitBoxOrient: "vertical",
                      minHeight: "3em",
                    }}
                  >
                    {account.ownerFirstName} {account.ownerLastName} (
                    {account.ownerEmail})
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                <Typography variant="caption">Account Id</Typography>
                <Typography variant="body3">
                  {account.amazonAccountId}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="caption">Marketplace</Typography>
                <Typography
                  variant="body3"
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: "2",
                    WebkitBoxOrient: "vertical",
                    minHeight: "3em",
                  }}
                >
                  {account?.amazonMarketplace?.country || "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="caption">
                  Advertising API Status
                </Typography>
                <Typography
                  variant="body3"
                  color={
                    account.adAccountStatus === "healthy" ? "green" : "red"
                  }
                >
                  <Iconify
                    icon={
                      account.adAccountStatus === "healthy"
                        ? "fluent:plug-connected-checkmark-20-filled"
                        : "fluent:plug-disconnected-20-filled"
                    }
                  />{" "}
                  {account.adAccountStatus}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="caption">
                  Selling Partner API Status
                </Typography>
                <Typography
                  variant="body3"
                  color={
                    account.spAccountStatus === "healthy" ? "green" : "red"
                  }
                >
                  <Iconify
                    icon={
                      account.spAccountStatus === "healthy"
                        ? "fluent:plug-connected-checkmark-20-filled"
                        : "fluent:plug-disconnected-20-filled"
                    }
                  />{" "}
                  {account.spAccountStatus}
                </Typography>
              </Grid>
              <Grid
                item
                container
                direction="column"
                spacing={4}
                xs={12}
                md={6}
              >
                <Grid item md={6}>
                  <Typography variant="caption">Portfolio State</Typography>
                  <Typography
                    variant="body3"
                    color={
                      account.portfolioState === "enabled" ? "green" : "red"
                    }
                  >
                    {account.portfolioState ?? "N/A"}
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography variant="caption">Portfolio Budget</Typography>
                  <Typography variant="body3">
                    ${account.portfolioBudgetAmount}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                container
                direction="column"
                spacing={1}
                xs={12}
                md={6}
              >
                <Grid item md={6}>
                  <Typography variant="caption">Listing Status</Typography>
                  <Typography
                    variant="body3"
                    color={
                      account.hasSuspendedListings === false ? "green" : "red"
                    }
                  >
                    {(account.hasSuspendedListings &&
                      "Has Suspended Listings") ||
                      (account.hasSuspendedListings == null && "N/A") ||
                      "healthy"}
                  </Typography>
                </Grid>
                <Grid item md={6}></Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ borderColor: "rgba(0, 0, 0, 0.2)" }} />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="caption">Last Sync</Typography>
                {formatUtcToLocalDateTime(account.lastSyncStartTime)
                  .split(",")
                  .map((place) => (
                    <Typography key={place} variant="body3">
                      {place}
                    </Typography>
                  ))}
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="caption">Next Sync</Typography>
                {account.nextSyncTime != null
                  ? formatUtcToLocalDateTime(account.nextSyncTime)
                      .split(",")
                      .map((place) => (
                        <Typography key={place} variant="body3">
                          {place}
                        </Typography>
                      ))
                  : "-"}
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Grid container justifyContent={"flex-start"} spacing={3}>
            {isPartnerAdmin && (
              <Grid item xs={"auto"}>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    dispatch(setSelectedManagedAccount(account || {}));
                    navigate(`/portal/accounts/${account.id}/manage`, {
                      state: admin,
                    });
                  }}
                >
                  Manage
                </Button>
              </Grid>
            )}
            <Grid item xs={"auto"}>
              {admin ? (
                <LoadingButton
                  variant="outlined"
                  size="small"
                  loading={resync.isLoading || resync.isFetching}
                  onClick={() => resync.mutate(account.id)}
                >
                  Resync
                </LoadingButton>
              ) : defaultAccount?.id === account.id ? (
                <Button variant="outlined" size="small" disabled>
                  Default
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  size="small"
                  disabled={!admin}
                  onClick={() => {
                    return setDefaultAccount.mutate({
                      defaultManagedAccountId: account.id,
                    });
                  }}
                >
                  Make Default
                </Button>
              )}
            </Grid>
            {admin && (
              <Grid item xs={"auto"}>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    navigate(
                      `/portal/accounts/${account.id}/manage?tabIndex=Job+Status`
                    );
                  }}
                >
                  Job Status
                </Button>
              </Grid>
            )}
          </Grid>
        </CardActions>
      </Card>
    </Grid>
  );
}
