import { Box, Card, CardHeader, Container } from "@mui/material";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import Spinner from "src/components/Spinner";
import { IsAdmin } from "../../slices/authSlice";
import JobStatusTable from "./JobStatusTable";
import { useGetJobDataForManagedAccount } from "src/hooks/admin";
import { useGetManagedAccount } from "src/hooks/managedAccount";

export default function ManagedAccountJobStatus({ accountId }) {
  const accountQuery = useGetManagedAccount(accountId);
  const isAdmin = useSelector(IsAdmin);
  const jobDataQuery = useGetJobDataForManagedAccount(accountId);

  if (!isAdmin) {
    return <Navigate replace to="/portal" />;
  }

  if (
    jobDataQuery.isLoading ||
    jobDataQuery.isIdle ||
    accountQuery.isLoading ||
    accountQuery.isIdle
  ) {
    return <Spinner />;
  }

  const jobData = jobDataQuery.data;

  return (
    <Container>
      <Card>
        <CardHeader
          titleTypographyProps={{ variant: "h3" }}
          title="Job Status"
          sx={{ pb: 1.5, pl: 6 }}
        />
        <Box>
          <JobStatusTable data={jobData} />
        </Box>
      </Card>
    </Container>
  );
}
