import { useSnackbar } from "notistack";
import { Navigate, useNavigate, useParams } from "react-router-dom";

// components
import Page from "../../components/Page";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import {
  useProductGroup,
  useProductGroupKeyword,
  useUpdateProductGroupKeyword,
} from "src/hooks/productGroup";
import Spinner from "src/components/Spinner";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { fCurrency, fPercent } from "src/utils/formatNumber";
import FormikTextField from "src/components/formik/FormikTextField";
import { useSelector } from "react-redux";
import { IsAdmin } from "src/slices/authSlice";
import BroadAndPhraseCard from "../products/BroadAndPhraseCard";
import ExactMatchSettingsCard from "../products/ExactMatchSettingsCard";

const schema = Yup.object().shape({
  searchVolume: Yup.number()
    .typeError("Search Volume must be a positive integer")
    .integer("Search Volume must be a positive integer")
    .moreThan(0, "Search Volume must be greater than 0")
    .optional()
    .nullable(true)
    .transform(function (value, originalValue) {
      if (this.isType(value)) return value;
      if (value === "") return null;
      if (value === undefined) return null;
      if (isNaN(value)) return null;
      return value;
    }),
  budget: Yup.number()
    .typeError("Budget must be a positive number")
    .min(0, "Budget can't be negative"),
  status: Yup.string().required("You must input a status"),
  algorithmState: Yup.string().required("You must input a state"),
  paidRatioBasis: Yup.number()
    .typeError("Paid Sell Through must be a positive integer")
    .integer("Paid Sell Through must be a positive integer")
    .min(1, "Paid Sell Through must be a positive integer"),
  organicRatioBasis: Yup.number()
    .typeError("Organic Sell Through must be a positive integer")
    .integer("Organic Sell Through must be a positive integer")
    .min(1, "Organic Sell Through must be a positive integer"),
  paidClickConversion: Yup.number()
    .typeError("PPC Conversion must be a number between 0 and 1")
    .moreThan(0, "PPC Conversion must be more than 0")
    .max(1, "PPC Conversion must be 1 or less"),
  seedPaidRankTarget: Yup.number()
    .typeError("Seed PPP must be a positive integer")
    .integer("Seed PPP must be a positive integer")
    .min(1, "PPP must be a positive integer"),
  seedPaidRankMax: Yup.number()
    .typeError("Seed Max PP must be a positive integer")
    .integer("Seed Max PP must be a positive integer")
    .min(1, "Seed Max PP must be a positive integer"),
  seedPaidRankMin: Yup.number()
    .typeError("Seed Min PP must be a positive integer")
    .integer("Seed Min PP must be a positive integer")
    .min(1, "Seed Min PP must be a positive integer"),
  seedOrganicRankTarget: Yup.number()
    .typeError("Seed POP must be a positive integer")
    .integer("Seed POP must be a positive integer")
    .min(1, "Seed POP must be a positive integer"),
  seedOrganicRankMax: Yup.number()
    .typeError("Seed Max OP must be a positive integer")
    .integer("Seed Max OP must be a positive integer")
    .min(1, "Seed Max OP must be a positive integer"),
  seedOrganicRankMin: Yup.number()
    .typeError("Seed Min OP must be a positive integer")
    .integer("Seed Min OP must be a positive integer")
    .min(1, "Seed Min OP must be a positive integer"),
  growOrganicRankTarget: Yup.number()
    .typeError("Grow POP must be a positive integer")
    .integer("Grow POP must be a positive integer")
    .min(1, "Grow POP must be a positive integer"),
  growOrganicRankMax: Yup.number()
    .typeError("Grow Max OP must be a positive integer")
    .integer("Grow Max OP must be a positive integer")
    .min(1, "Grow Max OP must be a positive integer"),
  growOrganicRankMin: Yup.number()
    .typeError("Grow Min OP must be a positive integer")
    .integer("Grow Min OP must be a positive integer")
    .min(1, "Grow Min OP must be a positive integer"),
  growPaidRankTarget: Yup.number()
    .typeError("Grow PPP must be a positive integer")
    .integer("Grow PPP must be a positive integer")
    .min(1, "Grow PPP must be a positive integer"),
  growPaidRankMax: Yup.number()
    .typeError("Grow Max PP must be a positive integer")
    .integer("Grow Max PP must be a positive integer")
    .min(1, "Grow Max PP must be a positive integer"),
  growPaidRankMin: Yup.number()
    .typeError("Grow Min PP must be a positive integer")
    .integer("Grow Min PP must be a positive integer")
    .min(1, "Grow Min PP must be a positive integer"),
  cropPaidRankTarget: Yup.number()
    .typeError("Crop PPP must be a positive integer")
    .integer("Crop PPP must be a positive integer")
    .min(1, "Crop PPP must be a positive integer"),
  cropPaidRankMax: Yup.number()
    .typeError("Crop Max PP must be a positive integer")
    .integer("Crop Max PP must be a positive integer")
    .min(1, "Crop Max PP must be a positive integer"),
  cropPaidRankMin: Yup.number()
    .typeError("Crop Min PP must be a positive integer")
    .integer("Crop Min PP must be a positive integer")
    .min(1, "Crop Min PP must be a positive integer"),
  cropOrganicRankTarget: Yup.number()
    .integer("Crop POP must be a positive integer")
    .integer("Crop POP must be a positive integer")
    .min(1, "Crop POP must be a positive integer"),
  cropOrganicRankMax: Yup.number()
    .typeError("Crop Max OP must be a positive integer")
    .integer("Crop Max OP must be a positive integer")
    .min(1, "Crop Max OP must be a positive integer"),
  cropOrganicRankMin: Yup.number()
    .typeError("Crop Min OP must be a positive integer")
    .integer("Crop Min OP must be a positive integer")
    .min(1, "Crop Min OP must be a positive integer"),
  broadMaxBid: Yup.number()
    .typeError("Broad Max Bid must be a positive number")
    .min(0, "Broad Max Bid can't be negative"),
  phraseMaxBid: Yup.number()
    .typeError("Phrase Max Bid must be a positive number")
    .min(0, "Phrase Max Bid can't be negative"),
  broadCurrentBid: Yup.number()
    .typeError("Broad Current Bid must be a positive number")
    .min(0, "Broad Current Bid can't be negative"),
  phraseCurrentBid: Yup.number()
    .typeError("Phrase Current Bid must be a positive number")
    .min(0, "Phrase Current Bid can't be negative"),
  broadConversionRate: Yup.number()
    .typeError("Broad Conversion Rate must be a number between 0 and 1")
    .moreThan(0, "Broad Conversion Rate must be more than 0")
    .max(1, "Broad Conversion Rate must be 1 or less"),
  phraseConversionRate: Yup.number()
    .typeError("Phrase Conversion Rate must be a number between 0 and 1")
    .moreThan(0, "Phrase Conversion Rate must be more than 0")
    .max(1, "Phrase Conversion Rate must be 1 or less"),
});

export default function EditKeyword() {
  const navigate = useNavigate();
  const params = useParams();
  const isAdmin = useSelector(IsAdmin);
  const { enqueueSnackbar } = useSnackbar();
  const productGroupQuery = useProductGroup(params.productGroupId);
  const keywordQuery = useProductGroupKeyword(
    params.productGroupId,
    params.keywordId
  );
  const UpdateKeyword = useUpdateProductGroupKeyword(
    params.productGroupId,
    params.keywordId
  );
  const productGroup = productGroupQuery.data;
  const keyword = keywordQuery.data;

  const formik = useFormik({
    initialValues: {
      searchVolume: keyword?.searchVolume || "",
      budget: keyword?.budget,
      status: keyword?.status || "Draft",
      algorithmState: keyword?.algorithmState,
      paidRatioBasis: keyword?.paidRatioBasis,
      organicRatioBasis: keyword?.organicRatioBasis,
      paidClickConversion: keyword?.paidClickConversion,
      seedPaidRankTarget: keyword?.seedPaidRankTarget,
      seedPaidRankMax: keyword?.seedPaidRankMax,
      seedPaidRankMin: keyword?.seedPaidRankMin,
      seedOrganicRankTarget: keyword?.seedOrganicRankTarget,
      seedOrganicRankMax: keyword?.seedOrganicRankMax,
      seedOrganicRankMin: keyword?.seedOrganicRankMin,
      growPaidRankTarget: keyword?.growPaidRankTarget,
      growPaidRankMax: keyword?.growPaidRankMax,
      growPaidRankMin: keyword?.growPaidRankMin,
      growOrganicRankTarget: keyword?.growOrganicRankTarget,
      growOrganicRankMax: keyword?.growOrganicRankMax,
      growOrganicRankMin: keyword?.growOrganicRankMin,
      cropPaidRankTarget: keyword?.cropPaidRankTarget,
      cropPaidRankMax: keyword?.cropPaidRankMax,
      cropPaidRankMin: keyword?.cropPaidRankMin,
      cropOrganicRankTarget: keyword?.cropOrganicRankTarget,
      cropOrganicRankMax: keyword?.cropOrganicRankMax,
      cropOrganicRankMin: keyword?.cropOrganicRankMin,
      broadEnabled: keyword?.broadEnabled,
      broadMaxBid: keyword?.broadMaxBid,
      broadCurrentBid: keyword?.broadCurrentBid,
      broadConversionRate: keyword?.broadConversionRate,
      phraseEnabled: keyword?.phraseEnabled,
      phraseMaxBid: keyword?.phraseMaxBid,
      phraseCurrentBid: keyword?.phraseCurrentBid,
      phraseConversionRate: keyword?.phraseConversionRate,
    },
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: (values, { setSubmitting }) => {
      values = schema.cast(values);
      return UpdateKeyword.mutate(
        {
          productGroupId: params.productGroupId,
          keyword: {
            id: keyword.id,
            ...values,
          },
        },
        {
          onSuccess: () => {
            enqueueSnackbar("Successfully Saved", { variant: "success" });
            navigate(-1);
          },
          onError: (error) => {
            enqueueSnackbar(error, { variant: "error" });
            setSubmitting(false);
          },
        }
      );
    },
  });

  const { values, errors, touched, handleSubmit, handleChange, getFieldProps } =
    formik;

  if (!isAdmin) {
    return <Navigate replace to="/portal" />;
  }

  if (keywordQuery.isLoading || productGroupQuery.isLoading) {
    return <Spinner />;
  }

  const netProfit =
    productGroup.primaryManagedAccountProduct.priceToPayAmount -
    productGroup.estimatedCost -
    productGroup.estimatedReferralFees -
    productGroup.estimatedOtherFees -
    productGroup.estimatedFbaFees;

  const maxBid =
    ((Number(values.organicRatioBasis) + Number(values.paidRatioBasis)) /
      values.paidRatioBasis) *
    netProfit *
    values.paidClickConversion;

  return (
    <Page
      title={`Edit Keyword: ${keyword.searchTerm.term}`}
      breadcrumbs={[
        { label: "Managed Accounts", route: "/portal/accounts" },
        {
          label: `${productGroup.managedAccount.nickName}`,
          route: "/portal/dashboard",
        },
        {
          label: `${
            productGroup.shortName ||
            productGroup.primaryManagedAccountProduct.title
          }`,
          route: "../",
        },
        {
          label: `Keyword: ${keyword.searchTerm.term}`,
          route: `../keyword/${keyword.id}`,
        },
        { label: "Edit" },
      ]}
    >
      <Container>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card>
                  <CardHeader
                    titleTypographyProps={{ variant: "h5" }}
                    title={`Keyword: ${keyword.searchTerm.term}`}
                  />
                  <CardContent>
                    <Grid container spacing={1}>
                      <Grid item xs={4}>
                        <Typography variant="caption" color="text.secondary">
                          Bidding Sku
                        </Typography>
                        <Typography>
                          {productGroup.primaryManagedAccountProduct.sku}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="caption" color="text.secondary">
                          Profit / Profit Margin
                        </Typography>
                        <Typography>
                          {" "}
                          {fCurrency(netProfit)} /{" "}
                          {fPercent(
                            (netProfit /
                              productGroup.primaryManagedAccountProduct
                                .priceToPayAmount) *
                              100
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <FormikTextField
                          fullWidth
                          formikName="searchVolume"
                          label="Search Volume"
                          variant="standard"
                          helperText={
                            touched.searchVolume && errors.searchVolume
                          }
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Stack alignItems={"center"}>
                      <Link
                        underline="hover"
                        onClick={() => navigate("advertisedSkus")}
                        sx={{ cursor: "pointer" }}
                      >
                        Edit Advertised Skus
                      </Link>
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12}>
                <BroadAndPhraseCard
                  title="Broad and Phrase Settings"
                  touched={touched}
                  errors={errors}
                  values={values}
                  handleChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <ExactMatchSettingsCard
                  title="Exact Match Settings"
                  touched={touched}
                  errors={errors}
                  values={values}
                  maxBid={maxBid}
                  handleChange={handleChange}
                  getFieldProps={getFieldProps}
                />
              </Grid>
              <Grid item container spacing={3} justifyContent="flex-end">
                <Grid item>
                  <Button
                    size="large"
                    variant="text"
                    color="error"
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <LoadingButton
                    size="large"
                    type="button"
                    variant="contained"
                    loading={formik.isSubmitting}
                    onClick={handleSubmit}
                  >
                    {`Save`}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </Container>
    </Page>
  );
}
