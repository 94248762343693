import React from "react";
import PropTypes from "prop-types";
import Error from "src/pages/Error";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo,
    });
  }

  render() {
    const { errorInfo, error } = this.state;
    const { children, homeRedirectOverride } = this.props;

    if (errorInfo !== null) {
      return (
        <Error
          clearError={() => this.setState({ error: null, errorInfo: null })}
        />
      );
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.any,
  app: PropTypes.any,
  homeRedirectOverride: PropTypes.string,
};

ErrorBoundary.defaultProps = {
  children: {},
};

export default ErrorBoundary;
