import { TableCell, TableRow, Collapse, Grid } from "@mui/material";
import { useState } from "react";

import SortableTable from "src/components/SortableTable";
import Spinner from "src/components/Spinner";
import { formatUtcToLocalDateTime } from "src/utils/formatTime";

const headCells = [
  {
    id: "id",
    numeric: true,
    disablePadding: false,
    label: "Job Id",
  },
  {
    id: "jobType",
    numeric: false,
    disablePadding: false,
    label: "Job Type",
  },
  {
    id: "active",
    numeric: false,
    disablePadding: false,
    label: "Active",
  },
  {
    id: "running",
    numeric: false,
    disablePadding: false,
    label: "Running",
  },
  {
    id: "lastRunningStart",
    numeric: false,
    disablePadding: false,
    label: "Last Running Start Time",
  },
  {
    id: "nextRunTime",
    numeric: false,
    disablePadding: false,
    label: "Next Run Time",
  },
  {
    id: "lastStatusMessage",
    numeric: false,
    disablePadding: false,
    label: "Last Status Message",
  },
  {
    id: "lastStatus",
    numeric: false,
    disablePadding: false,
    label: "Last Status",
  },
];

function creatData(data) {
  return {
    id: data.id,
    jobType: data.jobData.JobType,
    active: data.active,
    running: data.running,
    lastRunningStart: data.lastRunningStartTime,
    nextRunTime: data.nextRunTime,
    lastStatusMessage: data.jobData.StatusMessage,
    lastStatus: data.jobData.Status,
    jobData: JSON.stringify(data.jobData, null, "\t"),
  };
}

export default function JobStatusTable({ data }) {
  const mapFunction = (row) => <Row row={row} key={row.id} />;

  function Row({ row }) {
    const [open, setOpen] = useState(false);
    const onClick = () => setOpen(!open);
    return (
      <>
        <TableRow
          sx={{
            "&:last-child td, &:last-child th": { border: 0 },
            "&:nth-of-type(4n+1)": {
              backgroundColor: "background.disabled",
            },
          }}
          hover
          onClick={onClick}
        >
          <TableCell align="right">{row.id}</TableCell>
          <TableCell align="left">{row.jobType}</TableCell>
          <TableCell align="left">{row.active ? "Yes" : "No"}</TableCell>
          <TableCell align="left">{row.running ? "Yes" : "No"}</TableCell>
          <TableCell align="left">
            {formatUtcToLocalDateTime(row.lastRunningStart)}
          </TableCell>
          <TableCell align="left">
            {formatUtcToLocalDateTime(row.nextRunTime)}
          </TableCell>
          <TableCell align="left">{row.lastStatusMessage}</TableCell>
          <TableCell align="left">
            {row.lastStatus === 0 ? "Success" : "Fail"}
          </TableCell>
        </TableRow>
        <TableRow
          sx={{
            "&:last-child td, &:last-child th": { border: 0 },
            "&:nth-of-type(4n+2)": {
              backgroundColor: "background.disabled",
            },
          }}
        >
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
            <Collapse in={open}>
              <Grid padding={1} container justifyContent={"center"}>
                <Grid item>
                  <pre>{row.jobData}</pre>
                </Grid>
              </Grid>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  }

  if (!data) {
    return <Spinner />;
  }

  return (
    <SortableTable
      data={data.map((jobData) => creatData(jobData))}
      initialOrderBy="lastRunningStart"
      initialOrder="desc"
      headCells={headCells}
      mapFunction={mapFunction}
    />
  );
}
