import {
  TableCell,
  TableRow,
  CardHeader,
  Card,
  Box,
  TextField,
  Button,
  Stack,
} from "@mui/material";
import Papa from "papaparse";
import { useState } from "react";
import SortableTable from "src/components/SortableTable";
import { useProfitabilityReport2 } from "src/hooks/productGroup";
import { formatUTCDateToText } from "src/utils/formatTime";

const headCells = [
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    label: "Date",
  },
  {
    id: "totalGrossSales",
    numeric: true,
    disablePadding: false,
    label: "Total Gross Sales",
  },
  {
    id: "totalUnitsSold",
    numeric: true,
    disablePadding: false,
    label: "Total Units Sold",
  },
  {
    id: "CogsPerUnit",
    numeric: true,
    disablePadding: false,
    label: "Cogs Per Unit",
  },
  {
    id: "totalCogs",
    numeric: true,
    disablePadding: false,
    label: "Total Cogs",
  },
  {
    id: "acclioPpcUnitsSold",
    numeric: true,
    disablePadding: false,
    label: "Acclio PPC Units Sold",
  },
  {
    id: "acclioPpcAdSpend",
    numeric: true,
    disablePadding: false,
    label: "Acclio Ad Spend",
  },
  {
    id: "acclioPpcSales",
    numeric: true,
    disablePadding: false,
    label: "Acclio PPC Sales",
  },
  {
    id: "otherPpcUnitsSold",
    numeric: true,
    disablePadding: false,
    label: "Other PPC Units Sold",
  },
  {
    id: "otherPpcAdSpend",
    numeric: true,
    disablePadding: false,
    label: "Other Ad Spend",
  },
  {
    id: "otherPpcSales",
    numeric: true,
    disablePadding: false,
    label: "Other PPC Sales",
  },
  {
    id: "estimatedOrganicUnits",
    numeric: true,
    disablePadding: false,
    label: "Estimated Organic Units",
  },
  {
    id: "estimatedOrganicSales",
    numeric: true,
    disablePadding: false,
    label: "Estimated Organic Sales",
  },
  {
    id: "totalExpenses",
    numeric: true,
    disablePadding: false,
    label: "Total Expenses",
  },
  {
    id: "profit",
    numeric: true,
    disablePadding: false,
    label: "Profit",
  },
];

function downloadCSV(array, name) {
  var csv = Papa.unparse(array);

  var csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  var csvURL = null;
  if (navigator.msSaveBlob) {
    csvURL = navigator.msSaveBlob(csvData, "download.csv");
  } else {
    csvURL = window.URL.createObjectURL(csvData);
  }

  var tempLink = document.createElement("a");
  tempLink.href = csvURL;
  tempLink.setAttribute("download", name + "ProfitabilityReport2.csv");
  tempLink.click();
}

export default function ProfitabilityReportTable2({
  productGroupId,
  productGroupName,
}) {
  const defaultEnd = new Date();
  const defaultStart = new Date(new Date().setDate(defaultEnd.getDate() - 28));
  const [startDate, setStartDate] = useState(defaultStart.toLocaleDateString());
  const [endDate, setEndDate] = useState(defaultEnd.toLocaleDateString());
  const [reportParams, setReportParams] = useState({
    start: defaultStart.toLocaleDateString(),
    end: defaultEnd.toLocaleDateString(),
  });
  const reportQuery = useProfitabilityReport2(
    productGroupId,
    reportParams.start,
    reportParams.end
  );
  const report = reportQuery.data;

  const mapFunction = (row) => (
    <TableRow
      key={row.date}
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
      }}
    >
      <TableCell align="left">{formatUTCDateToText(row.date)}</TableCell>
      <TableCell align="right">
        {row.totalGrossSales != null
          ? "$" + row.totalGrossSales.toFixed(2)
          : "-"}
      </TableCell>
      <TableCell align="right">
        {row.totalUnitsSold != null ? row.totalUnitsSold : "-"}
      </TableCell>
      <TableCell align="right">
        {row.cogsPerUnit != null ? "$" + row.cogsPerUnit.toFixed(2) : "-"}
      </TableCell>
      <TableCell align="right">
        {row.totalCogs != null ? "$" + row.totalCogs.toFixed(2) : "-"}
      </TableCell>
      <TableCell align="right">
        {row.acclioPpcUnitsSold != null ? row.acclioPpcUnitsSold : "-"}
      </TableCell>
      <TableCell align="right">
        {row.acclioPpcAdSpend != null
          ? "$" + row.acclioPpcAdSpend.toFixed(2)
          : "-"}
      </TableCell>
      <TableCell align="right">
        {row.acclioPpcSales != null ? "$" + row.acclioPpcSales.toFixed(2) : "-"}
      </TableCell>
      <TableCell align="right">
        {row.otherPpcUnitsSold != null ? row.otherPpcUnitsSold : "-"}
      </TableCell>
      <TableCell align="right">
        {row.otherPpcAdSpend != null
          ? "$" + row.otherPpcAdSpend.toFixed(2)
          : "-"}
      </TableCell>
      <TableCell align="right">
        {row.otherPpcSales != null ? "$" + row.otherPpcSales.toFixed(2) : "-"}
      </TableCell>
      <TableCell align="right">
        {row.estimatedOrganicUnits != null ? row.estimatedOrganicUnits : "-"}
      </TableCell>
      <TableCell align="right">
        {row.estimatedOrganicSales != null
          ? "$" + row.estimatedOrganicSales.toFixed(2)
          : "-"}
      </TableCell>
      <TableCell align="right">
        {row.totalExpenses != null ? "$" + row.totalExpenses.toFixed(2) : "-"}
      </TableCell>
      <TableCell align="right">
        {row.profit != null ? "$" + row.profit.toFixed(2) : "-"}
      </TableCell>
    </TableRow>
  );

  const updateReport = () => {
    setReportParams({ start: startDate, end: endDate });
  };

  return (
    <Card>
      <CardHeader
        titleTypographyProps={{ variant: "h3" }}
        title="Profitability Report 2"
        sx={{ pb: 3, pl: 5 }}
      />
      <Stack direction="row" sx={{ pl: 5 }}>
        {" "}
        <TextField
          id="StartDate"
          label="Start Date"
          value={startDate}
          onChange={(event) => setStartDate(event.target.value)}
          sx={{ pr: 3 }}
        />
        <TextField
          id="EndDate"
          label="End Date"
          value={endDate}
          onChange={(event) => setEndDate(event.target.value)}
          sx={{ pr: 3 }}
        />
        <Button sx={{ p: 3 }} onClick={updateReport}>
          Load Data
        </Button>
        <Button
          sx={{ p: 3 }}
          onClick={() => downloadCSV(report, productGroupName)}
        >
          Download CSV
        </Button>
      </Stack>
      <Box sx={{ pl: 3, pr: 6 }}>
        {report !== undefined && (
          <SortableTable
            data={report}
            initialOrderBy="date"
            headCells={headCells}
            mapFunction={mapFunction}
          />
        )}
      </Box>
    </Card>
  );
}
