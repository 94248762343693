import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import FormikTextField from "src/components/formik/FormikTextField";
import { fCurrency } from "src/utils/formatNumber";
import AmazonPlacementCard from "./AmazonPlacementCard";

export default function ExactMatchSettingsCard(props) {
  const {
    title,
    touched,
    errors,
    values,
    handleChange,
    getFieldProps,
    maxBid,
  } = props;
  return (
    <Card>
      <CardHeader titleTypographyProps={{ variant: "h5" }} title={title} />
      <CardContent>
        <Grid container spacing={1} justifyContent="space-between">
          <Grid item xs={12}>
            <Typography variant="h6">General</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormikTextField
              fullWidth
              formikName="budget"
              label="Daily Budget"
              variant="standard"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              helperText={touched.budget && errors.budget}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              variant="standard"
              {...getFieldProps("status")}
              select
              label="Keyword Status"
              error={Boolean(touched.status && errors.status)}
              helperText={touched.status && errors.status}
              onChange={handleChange}
            >
              <MenuItem key={0} value="Draft">
                Draft
              </MenuItem>
              <MenuItem key={1} value="Inactive">
                Inactive
              </MenuItem>
              <MenuItem key={2} value="Crawl">
                Crawl
              </MenuItem>
              <MenuItem key={3} value="Automate">
                Automate
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={4}>
            {values.status === "Automate" ? (
              <TextField
                fullWidth
                variant="standard"
                {...getFieldProps("algorithmState")}
                select
                label="Algorithm State"
                error={Boolean(touched.algorithmState && errors.algorithmState)}
                helperText={touched.algorithmState && errors.algorithmState}
                onChange={handleChange}
              >
                <MenuItem key={0} value="Seed">
                  Seed
                </MenuItem>
                <MenuItem key={1} value="Grow">
                  Grow
                </MenuItem>
                <MenuItem key={2} value="Crop">
                  Crop
                </MenuItem>
              </TextField>
            ) : (
              <TextField
                fullWidth
                disabled
                variant="standard"
                label="Algorithm State"
                defaultValue={values.algorithmState}
                helperText="To change this value, set the Keyword Status to 'Automate'"
              />
            )}
          </Grid>
          <Grid item mt={2} xs={12}>
            <Typography variant="h6">Click Ratios and Conversion</Typography>
          </Grid>
          <Grid item xs={6} lg={4}>
            <FormikTextField
              fullWidth
              formikName="paidRatioBasis"
              label="Paid Sell Through"
              variant="standard"
              helperText={
                (touched.paidRatioBasis && errors.paidRatioBasis) || " "
              }
            />
          </Grid>
          <Grid item xs={6} lg={4}>
            <FormikTextField
              fullWidth
              formikName="organicRatioBasis"
              label="Organic Sell Through"
              variant="standard"
              helperText={
                (touched.organicRatioBasis && errors.organicRatioBasis) || " "
              }
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <Typography variant="caption" color="text.secondary">
              Sell Through Ratio
            </Typography>
            <Typography>
              {values.paidRatioBasis} : {values.organicRatioBasis}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormikTextField
              fullWidth
              formikName="paidClickConversion"
              label="PPC Conversion"
              variant="standard"
              helperText={
                (touched.paidClickConversion && errors.paidClickConversion) ||
                " "
              }
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography variant="caption" color="text.secondary">
              Calculated Max Bid
            </Typography>
            <Typography>{fCurrency(maxBid)}</Typography>
          </Grid>
          {values.status === "Automate" ? (
            <>
              {" "}
              <Grid item mb={2} xs={12}>
                <Typography variant="h6">Amazon Target Placement</Typography>
              </Grid>
              <AmazonPlacementCard touched={touched} errors={errors} />
            </>
          ) : (
            <></>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}
