import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  MenuItem,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import Iconify from "src/components/Iconify";
import Page from "src/components/Page";
import Spinner from "src/components/Spinner";
import { useRoleList } from "src/hooks/role";
import { useAddUserRole, useRemoveUserRole, useUserList } from "src/hooks/user";

const AddRoleModal = ({ user = { roles: [] }, roles = [], close, open }) => {
  const [selectedRole, setSelectedRole] = useState();
  const availableRoles = roles.filter(
    (x) => !user?.roles?.some((ur) => ur.id === x.id)
  );
  const addUserRole = useAddUserRole();
  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setSelectedRole("");
    close();
  };

  const handleAddRole = (userId, roleId) => {
    addUserRole.mutate(
      {
        userId,
        roleId,
      },
      {
        onSuccess: () => {
          close();
          setSelectedRole("");
          enqueueSnackbar("Added Role", {
            variant: "success",
            autoHideDuration: 2000,
          });
        },
        onError: (error) => {
          enqueueSnackbar(error, {
            variant: "error",
            autoHideDuration: 2000,
          });
        },
      }
    );
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Add Role</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Select a role from the list below to add another role to this user
        </DialogContentText>
        <TextField
          autoFocus
          select
          margin="normal"
          id="role"
          value={selectedRole || ""}
          label="Role to Add"
          fullWidth
          onChange={(e) => setSelectedRole(e.target.value)}
        >
          {availableRoles.map((role) => (
            <MenuItem key={role.id} value={role.id}>
              {role.name}
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <LoadingButton
          disabled={!selectedRole}
          loading={addUserRole.isLoading}
          onClick={() => handleAddRole(user.id, selectedRole)}
        >
          Add
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default function Users() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();

  //component state
  const [addRoleOpen, setAddRoleOpen] = useState(false);
  const [addingRoleForUser, setAddingRoleForUser] = useState({});
  const [removeInProgress, setRemoveInProgress] = useState([]);
  const pageIndex = searchParams.get("pageIndex") || 1;

  //react query hooks
  const usersQuery = useUserList(pageIndex - 1, 10);
  const rolesQuery = useRoleList();
  const users = usersQuery.data?.entries || [];
  const roles = rolesQuery.data || [];
  const removeRole = useRemoveUserRole();

  //component functions
  const setPageIndex = (newVal) => {
    setSearchParams({ ...searchParams, pageIndex: newVal });
  };

  const handleRoleOpen = (user) => {
    setAddRoleOpen(true);
    setAddingRoleForUser(user);
  };

  const handleRemoveUserRole = (userId, roleId) => {
    setRemoveInProgress(removeInProgress.concat({ userId, roleId }));
    removeRole.mutate(
      { userId, roleId },
      {
        onSuccess: () => {
          setRemoveInProgress((val) =>
            val.filter((x) => x.userId !== userId && x.roleId !== roleId)
          );
          enqueueSnackbar("Role Removed", {
            variant: "success",
            autoHideDuration: 2000,
          });
        },
      }
    );
  };

  return (
    <Page
      title="Users"
      breadcrumbs={[
        { label: "Admin" },
        { label: "Users" },
        { label: `Page ${pageIndex}` },
      ]}
    >
      <Container>
        <AddRoleModal
          open={addRoleOpen}
          user={addingRoleForUser}
          close={() => setAddRoleOpen(false)}
          roles={roles}
        />
        <Stack direction="column" spacing={1} alignItems="center">
          {usersQuery.isLoading ? (
            <>
              <Typography variant="body2">Retrieving users</Typography>
              <Spinner />
            </>
          ) : (
            <>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>User Id</TableCell>
                      <TableCell>Email (userName)</TableCell>
                      <TableCell>Roles</TableCell>
                      <TableCell align="center">Add Role</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users.map((user) => (
                      <TableRow key={user.id}>
                        <TableCell>{user.id}</TableCell>
                        <TableCell>{user.userName}</TableCell>
                        <TableCell sx={{ width: "1%", whiteSpace: "nowrap" }}>
                          <Stack spacing={0} direction="column">
                            {user.roles.map((role) => (
                              <Box
                                key={role.id}
                                justifyContent="space-between"
                                alignItems="center"
                                display="flex"
                              >
                                {role.name}
                                {removeInProgress.some(
                                  (x) =>
                                    x.roleId === role.id && x.userId === user.id
                                ) ? (
                                  <CircularProgress size={20} />
                                ) : (
                                  <IconButton
                                    size="small"
                                    color="error"
                                    onClick={() =>
                                      handleRemoveUserRole(user.id, role.id)
                                    }
                                  >
                                    <Iconify icon="eva:trash-2-fill" />
                                  </IconButton>
                                )}
                              </Box>
                            ))}
                          </Stack>
                        </TableCell>
                        <TableCell align="center">
                          <IconButton
                            color="primary"
                            size="small"
                            onClick={() => handleRoleOpen(user)}
                          >
                            <Iconify icon="eva:plus-circle-fill" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Pagination
                count={usersQuery.data.totalPages}
                page={usersQuery.data.pageNumber + 1}
                showFirstButton
                showLastButton
                onChange={(_, v) => {
                  setPageIndex(v);
                  window.scrollTo(0, 0);
                }}
              />
            </>
          )}
        </Stack>
      </Container>
    </Page>
  );
}
