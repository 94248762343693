import { useMutation } from "react-query";
import api from "../services/api";

// API methods - not exposed outside this class

const getHtmlContentLink = async ({ searchId }) => {
  const { data } = await api.get(`/searchData/HtmlContentLink/${searchId}`);
  return data;
};

// Custom hooks
export const useGetHtmlContentLink = () => {
  return useMutation(getHtmlContentLink);
};
