import { useParams } from "react-router-dom";

// components
import Spinner from "../../components/Spinner";
import Page from "src/components/Page";
import KeywordSearchHistory from "./KeywordSearchHistory";

import { SelectedManagedAccountName } from "src/slices/userSlice";
import { useSelector } from "react-redux";
import { Avatar, Container, Grid } from "@mui/material";
import {
  useProductGroup,
  useProductGroupKeyword,
} from "src/hooks/productGroup";

export default function KeywordDashboard() {
  const params = useParams();
  const productGroupId = params.productGroupId;
  const productGroupQuery = useProductGroup(productGroupId);
  const keywordQuery = useProductGroupKeyword(productGroupId, params.keywordId);
  const selectedAccountName = useSelector(SelectedManagedAccountName);

  if (
    productGroupQuery.isLoading ||
    keywordQuery.isLoading ||
    keywordQuery.isIdle
  ) {
    return <Spinner />;
  }
  const productGroup = productGroupQuery.data;
  const keyword = keywordQuery.data;

  if (!keyword) {
    return <Spinner />;
  }

  return (
    <Page
      title={keyword.searchTerm.term}
      breadcrumbs={[
        { label: "Managed Accounts", route: "/portal/accounts" },
        { label: selectedAccountName, route: "/portal/dashboard" },
        { label: productGroup.shortName, route: "../" },
        { label: `Keyword: ${keyword.searchTerm.term}` },
      ]}
    >
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Avatar
              variant="square"
              src={productGroup.primaryManagedAccountProduct.imageUrl.replace(
                "SS60",
                "SS368"
              )}
              sx={{ width: 120, height: 120 }}
            />
          </Grid>
          <Grid item xs={12}>
            <KeywordSearchHistory
              productGroupId={productGroup.id}
              keyword={keyword}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
