import React from "react";
import { Navigate, Outlet, useRoutes } from "react-router-dom";

import UnauthenticatedLayout from "./layouts/UnauthenticatedLayout";

// non lazy loaded pages
import PrivacyPolicy from "./pages/PrivacyPolicy";
import NotFound from "./pages/Page404";
import { Suspense } from "react";
import Spinner from "./components/Spinner";
import OauthRedirect from "./pages/OauthRedirect";
import Admin from "./pages/admin/Admin";
import EditAccount from "./pages/accounts/EditAccount";
import EditKeyword from "./pages/keywords/EditKeyword";
import KeywordDashboard from "./pages/keywords/KeywordDashboard";
import MuiComponentSamples from "./pages/admin/MuiComponentSamples";
import ProductProfitabilityReport from "./pages/products/ProductProfitabilityReport";
import ManagedAccountJobStatus from "./pages/admin/ManagedAccountJobStatus";
import ProductGroupJobStatus from "./pages/admin/ProductGroupJobStatus";
import CampaignSalesAttributionReport from "./pages/products/CampaignSalesAttributionReport";
import BroadAndPhraseSettings from "./pages/products/BroadAndPhraseSettings";
import ProductProfitabilityReport2 from "./pages/products/ProductProfitabilityReport2";
import OpenCachedPage from "./pages/OpenCachedPage";
import SettingsDashboard from "./pages/dashboard/SettingsDashboard";
import EditDefaultAdvertisedSkus from "./pages/products/EditDefaultAdvertisedSkus";
import EditAdvertisedSkusForKeyword from "./pages/products/EditAdvertisedSkusForKeyword";
import PartnerDashboard from "./pages/partner/Dashboard";
import Users from "./pages/admin/Users";

// lazy loaded components for code splitting based on routes
const PortalLayout = React.lazy(() => import("./layouts/portal"));
const Login = React.lazy(() => import("./pages/login/Login"));
const Register = React.lazy(() => import("./pages/register/Register"));
const EditProduct = React.lazy(() => import("./pages/products/EditProduct"));
const ProductDashboard = React.lazy(() =>
  import("./pages/products/ProductDashboard")
);
const Dashboard = React.lazy(() => import("./pages/dashboard/Dashboard"));
const Verify = React.lazy(() => import("./pages/Verify"));
const ForgotPassword = React.lazy(() =>
  import("./pages/forgotPassword/ForgotPassword")
);
const PassswordReset = React.lazy(() => import("./pages/PasswordReset"));
const Accounts = React.lazy(() => import("./pages/accounts/Accounts"));
const AddAccount = React.lazy(() => import("./pages/accounts/AddAccount"));
const AcceptInvite = React.lazy(() => import("./pages/AcceptInvite"));

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: "/portal",
      element: (
        <Suspense fallback={<Spinner />}>
          <PortalLayout />
        </Suspense>
      ),
      children: [
        { path: "", element: <Navigate to="/portal/dashboard" replace /> },
        // { path: "settings", element: <SettingsDashboard /> },
        {
          path: "dashboard",
          element: <Outlet />,
          children: [
            { path: "", element: <Dashboard /> },
            {
              path: "products",
              element: <Outlet />,
              children: [
                {
                  path: "edit/:productGroupId",
                  element: <Outlet />,
                  children: [
                    { path: "", element: <EditProduct /> },
                    {
                      path: "defaultAdvertisedSkus",
                      element: <EditDefaultAdvertisedSkus />,
                    },
                  ],
                },
                {
                  path: ":productGroupId",
                  element: <Outlet />,
                  children: [
                    { path: "", element: <ProductDashboard /> },
                    {
                      path: "profitabilityReport",
                      element: <ProductProfitabilityReport />,
                    },
                    {
                      path: "profitabilityReport2",
                      element: <ProductProfitabilityReport2 />,
                    },
                    {
                      path: "campaignSalesAttributionReport",
                      element: <CampaignSalesAttributionReport />,
                    },
                    {
                      path: "broadAndPhraseSettings",
                      element: <BroadAndPhraseSettings />,
                    },
                    {
                      path: "keyword/edit/:keywordId",
                      element: <Outlet />,
                      children: [
                        { path: "", element: <EditKeyword /> },
                        {
                          path: "AdvertisedSkus",
                          element: <EditAdvertisedSkusForKeyword />,
                        },
                      ],
                    },
                    {
                      path: "keyword/:keywordId",
                      element: <KeywordDashboard />,
                    },
                  ],
                },
              ],
            },
          ],
        },

        {
          path: "accounts",
          element: <Outlet />,
          children: [
            { path: "", element: <Accounts /> },
            { path: "add", element: <AddAccount /> },
            { path: ":accountId/edit", element: <EditAccount /> },
            { path: ":accountId/manage", element: <PartnerDashboard /> },
          ],
        },
        { path: "admin/users", element: <Users /> },
        {
          path: "admin/managedAccount/:accountId/productGroup/:productGroupId/jobStatus",
          element: <ProductGroupJobStatus />,
        },
        { path: "admin/sample", element: <MuiComponentSamples /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "/invite",
      element: (
        <Suspense fallback={<Spinner fixedPosition={true} />}>
          <AcceptInvite />
        </Suspense>
      ),
    },
    {
      path: "/OpenCachedPage",
      element: <OpenCachedPage />,
    },
    {
      path: "/404",
      element: <NotFound />,
    },
    {
      path: "/OAuthRedirect",
      element: <OauthRedirect />,
    },
    {
      path: "/public",
      element: <Outlet />,
      children: [{ path: "privacyPolicy", element: <PrivacyPolicy /> }],
    },
    {
      path: "/",
      element: <UnauthenticatedLayout />,
      children: [
        { path: "/", element: <Navigate to="/portal/dashboard" replace /> },
        { path: "login", element: <Login /> },
        { path: "register", element: <Register /> },
        { path: "verify", element: <Verify /> },
        { path: "forgotPassword", element: <ForgotPassword /> },
        { path: "passwordReset", element: <PassswordReset /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" /> },
  ]);
}
