import { Box } from "@mui/material";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, size = 40, sx }) {
  // OR
  const logo = (
    <Box sx={sx}>
      <picture>
        <source srcSet="/static/logo512.webp" type="image/webp" />
        <img src="/static/logo512.png" alt="Acclio" height={size} />
      </picture>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
