import { useQuery, useMutation, useQueryClient } from "react-query";
import api from "../services/api";
import { ObjectToQueryString } from "src/utils/uri";

// API methods - not exposed outside this class
const getPartners = async (options) => {
  const { data } = await api.get(`/partner?${ObjectToQueryString(options)}`);
  return data;
};

const getPartner = async (partnerId) => {
  const { data } = await api.get(`/partner/${partnerId}`);
  return data;
};

const getPartnerUsers = async (partnerId) => {
  const { data } = await api.get(`/partner/${partnerId}/user`);
  return data;
};

const getPartnerInviteList = async (partnerId) => {
  const { data } = await api.get(`/partner/${partnerId}/invite`);
  return data;
};

const addPartner = async (partner) => {
  const { data } = await api.post(`/partner`, partner);
  return data;
};

const addPartnerUserRole = async ({ partnerId, addRoleModel }) => {
  const { data } = await api.post(`/partner/${partnerId}/user`, addRoleModel);
  return data;
};

const deletePartnerUserRole = async ({ partnerId, userId, roleId }) => {
  const { data } = await api.delete(
    `/partner/${partnerId}/user/${userId}/role/${roleId}`
  );
  return data;
};

const invitePartnerUser = async ({ partnerId, invite }) => {
  const { data } = await api.post(`/partner/${partnerId}/invite`, invite);
  return data;
};

const resendPartnerUserInvite = async ({ partnerId, inviteId }) => {
  const { data } = await api.post(
    `/partner/${partnerId}/invite/${inviteId}/resend`
  );
  return data;
};

const deletePartnerUserInvite = async ({ partnerId, inviteId }) => {
  const { data } = await api.delete(`/partner/${partnerId}/invite/${inviteId}`);
  return data;
};

const disablePartnerUser = async ({ partnerId, userId }) => {
  const { data } = await api.post(
    `/partner/${partnerId}/user/${userId}/disable`
  );
  return data;
};

// Custom hooks
export const usePartner = (partnerId) => {
  return useQuery(["partner", { partnerId }], () => getPartner(partnerId));
};

export const usePartnerList = (page = 0, perPage = 10, filter = null) => {
  return useQuery(["partnerList", { page, perPage, filter }], () =>
    getPartners({ page, perPage, filter })
  );
};

export const useAddPartnerUserRole = () => {
  const queryClient = useQueryClient();

  return useMutation(addPartnerUserRole, {
    onSuccess: (data, { partnerId }) => {
      return queryClient.invalidateQueries([
        "partnerUserList",
        { partnerId: partnerId },
      ]);
    },
  });
};

export const useRemovePartnerUserRole = () => {
  const queryClient = useQueryClient();

  return useMutation(deletePartnerUserRole, {
    onSuccess: (data, { partnerId }) => {
      return queryClient.invalidateQueries([
        "partnerUserList",
        { partnerId: partnerId },
      ]);
    },
  });
};

export const usePartnerUserList = (partnerId) => {
  return useQuery(["partnerUserList", { partnerId }], () =>
    getPartnerUsers(partnerId)
  );
};

export const useAddPartner = () => {
  const queryClient = useQueryClient();

  return useMutation(addPartner, {
    onSuccess: (data) => {
      return queryClient.invalidateQueries(["partnerList"]);
    },
  });
};

export const usePartnerInviteList = (partnerId) => {
  return useQuery(["partnerInviteList", { partnerId }], () =>
    getPartnerInviteList(partnerId)
  );
};

export const useInvitePartnerUser = () => {
  const queryClient = useQueryClient();

  return useMutation(invitePartnerUser, {
    onSuccess: (data, { partnerId }) =>
      queryClient.invalidateQueries([
        "partnerInviteList",
        { partnerId: partnerId },
      ]),
  });
};

export const useResendPartnerUserInvite = () => {
  const queryClient = useQueryClient();

  return useMutation(resendPartnerUserInvite, {
    onSuccess: (data, { partnerId }) =>
      queryClient.invalidateQueries([
        "partnerInviteList",
        { partnerId: partnerId },
      ]),
  });
};

export const useDeletePartnerUserInvite = () => {
  const queryClient = useQueryClient();

  return useMutation(deletePartnerUserInvite, {
    onSuccess: (data, { partnerId }) =>
      queryClient.invalidateQueries([
        "partnerInviteList",
        { partnerId: partnerId },
      ]),
  });
};

export const useDisablePartnerUser = () => {
  const queryClient = useQueryClient();

  return useMutation(disablePartnerUser, {
    onSuccess: (data, { partnerId }) => {
      return queryClient.invalidateQueries([
        "partnerUserList",
        { partnerId: partnerId },
      ]);
    },
  });
};
