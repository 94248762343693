import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { Fragment, useState } from "react";
import {
  useAddPartnerUserRole,
  useDisablePartnerUser,
  usePartnerInviteList,
  usePartnerUserList,
  useRemovePartnerUserRole,
} from "src/hooks/partner";
import Spinner from "src/components/Spinner";
import Iconify from "src/components/Iconify";
import { InviteUserListDialog } from "./InviteUserListDialog";
import { InviteUserModal } from "./InviteUserModal";
import AddRoleModal from "src/components/AddRoleModal";
import { usePartnerRoleList } from "src/hooks/role";
import { useSnackbar } from "notistack";
import CustomTabPanel from "../../components/CustomTabPanel";

export default function PartnerUsers({
  partnerId,
  partner = { locations: [] },
}) {
  const { enqueueSnackbar } = useSnackbar();
  // component state
  const [inviteDialogOpen, setInviteDialogOpen] = useState(false);
  const [inviteListDialogOpen, setInviteListDialogOpen] = useState(false);
  const [addRoleOpen, setAddRoleOpen] = useState(false);
  const [addingRoleForUser, setAddingRoleForUser] = useState({});
  const [removeInProgress, setRemoveInProgress] = useState([]);
  const [disableInProgress, setDisableInProgress] = useState([]);
  const [tab, setTab] = useState(0);

  // query and mutation hooks
  const partnerRoles = usePartnerRoleList();
  const partnerUserList = usePartnerUserList(partnerId);
  const partnerInviteList = usePartnerInviteList(partnerId);
  const addRole = useAddPartnerUserRole();
  const removeRole = useRemovePartnerUserRole();
  const disableUser = useDisablePartnerUser();

  const pendingInviteCount = partnerInviteList.isLoading
    ? "Loading "
    : partnerInviteList.data.filter((x) => {
        const expireDate = new Date(x.expires);
        return expireDate > Date.now() && !x.accepted;
      }).length;

  const handleRemoveUserRole = (userId, roleId) => {
    setRemoveInProgress(removeInProgress.concat({ userId, roleId }));
    removeRole.mutate(
      { partnerId, userId, roleId },
      {
        onSuccess: () => {
          enqueueSnackbar("Role Removed", {
            variant: "success",
            autoHideDuration: 2000,
          });
        },
        onSettled: () => {
          setRemoveInProgress((val) =>
            val.filter((x) => x.userId !== userId && x.roleId !== roleId)
          );
        },
      }
    );
  };

  const handleRoleOpen = (user) => {
    setAddRoleOpen(true);
    setAddingRoleForUser(user);
  };

  const handleAddRole = (userId, roleId) => {
    addRole.mutate(
      {
        partnerId,
        addRoleModel: { userId, roleId },
      },
      {
        onSuccess: () => {
          setAddRoleOpen(false);
          enqueueSnackbar("Added Role", {
            variant: "success",
            autoHideDuration: 2000,
          });
        },
        onError: (error) => {
          enqueueSnackbar(error, {
            variant: "error",
            autoHideDuration: 2000,
          });
        },
      }
    );
  };

  const handleChange = (event, newValue) => {
    setTab(newValue);
    event.preventDefault();
  };

  const handleDisableUser = (userId) => {
    setDisableInProgress(disableInProgress.concat({ userId }));
    disableUser.mutate(
      { partnerId, userId },
      {
        onSuccess: () => {
          enqueueSnackbar("User Disabled", {
            variant: "success",
            autoHideDuration: 2000,
          });
        },
        onSettled: () => {
          setDisableInProgress((val) => val.filter((x) => x.userId !== userId));
        },
      }
    );
  };

  return (
    <>
      {addRoleOpen && (
        <AddRoleModal
          open={addRoleOpen}
          user={addingRoleForUser}
          close={() => setAddRoleOpen(false)}
          roles={partnerRoles.data}
          handleAddRole={handleAddRole}
          // isLoading={addUserRole.isLoading}
        />
      )}
      <InviteUserModal
        partnerId={partnerId}
        open={inviteDialogOpen}
        close={() => setInviteDialogOpen(false)}
        roles={partnerRoles.data}
      />
      <InviteUserListDialog
        partnerId={partnerId}
        open={inviteListDialogOpen}
        close={() => setInviteListDialogOpen(false)}
        invitations={partnerInviteList.data}
      />
      <Stack direction="column" spacing={2} marginTop={2} maxWidth={"md"}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Tabs value={tab} onChange={handleChange}>
            <Tab label="Active" id={0} />
            <Tab label="Disabled" id={1} />
          </Tabs>
          <Button variant="outlined" onClick={() => setInviteDialogOpen(true)}>
            Invite Users
          </Button>
          <Button variant="text" onClick={() => setInviteListDialogOpen(true)}>
            View Pending Invitations ({pendingInviteCount})
          </Button>
        </Stack>
        {partnerUserList.isLoading ? (
          <Spinner />
        ) : !partnerUserList.data?.length ? (
          <Typography>No users exist yet. Invite users above.</Typography>
        ) : (
          <Fragment>
            <CustomTabPanel value={tab} index={0}>
              <ActiveUserTable
                userData={partnerUserList.data?.filter((x) => {
                  return x.active;
                })}
                removeInProgress={removeInProgress}
                handleRemoveUserRole={handleRemoveUserRole}
                handleRoleOpen={handleRoleOpen}
                disableInProgress={disableInProgress}
                handleDisableUser={handleDisableUser}
              />
            </CustomTabPanel>
            <CustomTabPanel value={tab} index={1}>
              <DisabledUserTable
                userData={partnerUserList.data?.filter((x) => {
                  return !x.active;
                })}
                handleRoleOpen={handleRoleOpen}
              />
            </CustomTabPanel>
          </Fragment>
        )}
      </Stack>
    </>
  );
}

function ActiveUserTable({
  userData,
  removeInProgress,
  handleRemoveUserRole,
  handleRoleOpen,
  disableInProgress,
  handleDisableUser,
}) {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>First Name</TableCell>
            <TableCell>Last Name</TableCell>
            <TableCell>Roles</TableCell>
            <TableCell>Add Role</TableCell>
            <TableCell>Deactivate</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {userData.map((user) => (
            <TableRow key={user.id} hover>
              <TableCell>{user.userName}</TableCell>
              <TableCell>{user.firstName}</TableCell>
              <TableCell>{user.lastName}</TableCell>
              <TableCell sx={{ width: "1%", whiteSpace: "nowrap" }}>
                <Stack spacing={0} direction="column">
                  {user.roles.map((role) => (
                    <Box
                      key={role.id}
                      justifyContent="space-between"
                      alignItems="center"
                      display="flex"
                    >
                      {role.name}
                      {removeInProgress.some(
                        (x) => x.roleId === role.id && x.userId === user.id
                      ) ? (
                        <CircularProgress size={20} />
                      ) : (
                        <Tooltip title="Remove">
                          <IconButton
                            size="small"
                            color="error"
                            onClick={() =>
                              handleRemoveUserRole(user.id, role.id)
                            }
                          >
                            <Iconify icon="eva:trash-2-fill" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>
                  ))}
                </Stack>
              </TableCell>
              <TableCell>
                {" "}
                <Tooltip title="Add Role">
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={() => handleRoleOpen(user)}
                  >
                    <Iconify icon="eva:plus-circle-fill" />
                  </IconButton>
                </Tooltip>
              </TableCell>
              <TableCell>
                {disableInProgress.some((x) => x.userId === user.id) ? (
                  <CircularProgress size={20} />
                ) : (
                  <Tooltip title="Disable User">
                    <IconButton
                      size="small"
                      color="error"
                      onClick={() => handleDisableUser(user.id)}
                    >
                      <Iconify icon="eva:trash-2-fill" />
                    </IconButton>
                  </Tooltip>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function DisabledUserTable({ userData, handleRoleOpen }) {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>First Name</TableCell>
            <TableCell>Last Name</TableCell>
            <TableCell>Reactivate</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {userData.map((user) => (
            <TableRow key={user.id} hover>
              <TableCell>{user.userName}</TableCell>
              <TableCell>{user.firstName}</TableCell>
              <TableCell>{user.lastName}</TableCell>
              <TableCell>
                {" "}
                <Tooltip title="Reactivate">
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={() => handleRoleOpen(user)}
                  >
                    <Iconify icon="eva:plus-circle-fill" />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
