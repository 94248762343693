// @mui
import { styled } from "@mui/material/styles";
import { Typography, Container, Box } from "@mui/material";
// components
import Page from "../components/Page";
import Spinner from "src/components/Spinner";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

// ----------------------------------------------------------------------

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(6, 0),
}));

// ----------------------------------------------------------------------

export default function OauthRedirect() {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const target = window.opener;
    const otherData = {};
    for (const entry of searchParams.entries()) {
      const [param, value] = entry;
      otherData[param] = value;
    }

    target.postMessage({ messageType: "spapi", otherData });
    // window.close();
  }, [searchParams]);

  return (
    <Page title="OAuth Redirect">
      <Container>
        <ContentStyle sx={{ textAlign: "center", alignItems: "center" }}>
          <Typography variant="h3" paragraph>
            Verifying authorized account
          </Typography>

          <Typography sx={{ color: "text.secondary" }}>
            We are verifying and validating the account you just authorized,
            this window will close on it's own.
          </Typography>

          <Box sx={{ mx: "auto", marginTop: { xs: 5, sm: 5 } }}>
            <Spinner />
          </Box>
        </ContentStyle>
      </Container>
    </Page>
  );
}
