import { useQuery, useMutation, useQueryClient } from "react-query";
import api from "../services/api";

// API methods - not exposed outside this class
const get = async (accountId) => {
  const { data } = await api.get(`/managedAccount/${accountId}`);
  return data;
};

const getAll = async () => {
  const { data } = await api.get("/managedAccount");
  return data;
};

const adminGetAll = async () => {
  const { data } = await api.get("/admin/managedAccount");
  return data;
};

const create = async (account) => {
  const { data } = await api.post("/managedAccount", account);
  return data;
};

const edit = async (account) => {
  const { data } = await api.put(`/managedAccount/${account.id}`, {
    ...account,
    externalNamingPrefix: account.externalNamingPrefix || "Accl.io",
  });
  return data;
};

const resync = async (accountId) => {
  const { data } = await api.post(`/managedAccount/${accountId}/resync`);
  return data;
};

// Custom hooks
export const useGetManagedAccount = (accountId) => {
  return useQuery(`ManagedAccounts~${accountId}`, () => {
    return get(accountId);
  });
};

export const useManagedAccounts = () => {
  return useQuery("ManagedAccounts", () => {
    return getAll();
  });
};

export const useAdminManagedAccounts = () => {
  return useQuery("AdminManagedAccounts", () => {
    return adminGetAll();
  });
};

export const useCreateManagedAccount = (account) => {
  const queryClient = useQueryClient();

  return useMutation(create, {
    onSuccess: () => {
      queryClient.invalidateQueries("ManagedAccounts");
    },
  });
};

export const useEditManagedAccount = (account) => {
  const queryClient = useQueryClient();

  return useMutation(edit, {
    onSuccess: () => {
      queryClient.invalidateQueries(`ManagedAccounts~${account.id}`);
      queryClient.invalidateQueries(`ManagedAccounts`);
    },
  });
};

export const useResyncManagedAccount = () => {
  return useMutation(resync);
};
