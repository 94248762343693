import { useQuery, useMutation, useQueryClient } from "react-query";
import api from "../services/api";

// API methods - not exposed outside this class
const getJobDataForManagedAccount = async (accountId) => {
  const { data } = await api.get(`/admin/managedAccount/${accountId}/jobData`);
  return data;
};

const getJobDataForProductGroup = async (productGroupId) => {
  const { data } = await api.get(
    `/admin/productGroup/${productGroupId}/jobData`
  );
  return data;
};

// Custom hooks
export const useGetJobDataForManagedAccount = (accountId) => {
  return useQuery(["JobDataForManagedAccount", accountId], () => {
    return getJobDataForManagedAccount(accountId);
  });
};

export const useGetJobDataForProductGroup = (productGroupId) => {
  return useQuery(["JobDataForProductGroup", productGroupId], () => {
    return getJobDataForProductGroup(productGroupId);
  });
};
