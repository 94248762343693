import { Suspense } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Spinner from "src/components/Spinner";
import { IsLoggedOn } from "src/slices/authSlice";

export default function UnauthenticatedLayout() {
  const authenticated = useSelector(IsLoggedOn);
  const location = useLocation();

  // get the location originally requested (if applicable)
  let { from } = location.state || {};
  const destination = (from && from.pathname) || "/portal/dashboard";

  if (authenticated) {
    return <Navigate to={destination} />;
  }

  return (
    <Suspense fallback={<Spinner />}>
      <Outlet />
    </Suspense>
  );
}
