import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Container,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Page from "src/components/Page";
import SortableTable from "src/components/SortableTable";
import Spinner from "src/components/Spinner";
import {
  useProductGroup,
  useUpdateProductGroupDefaultAdvertisedSkus,
} from "src/hooks/productGroup";

export default function EditDefaultAdvertisedSkus() {
  const params = useParams();
  const productGroupQuery = useProductGroup(params.productGroupId);
  const productGroup = productGroupQuery.data;
  const updateDefaultAdvertisedSkus =
    useUpdateProductGroupDefaultAdvertisedSkus(params.productGroupId);
  const currentAdvertisedSkus = productGroup?.defaultAdvertisedSkus ?? [];
  if (productGroupQuery.isLoading) {
    return <Spinner />;
  }
  return (
    <Page
      title={`Edit Product: ${productGroup.primaryManagedAccountProduct.sku}`}
      breadcrumbs={[
        { label: "Managed Accounts", route: "/portal/accounts" },
        {
          label: `${productGroup.managedAccount.nickName}`,
          route: "/portal/dashboard",
        },
        {
          label: `${
            productGroup.shortName ||
            productGroup.primaryManagedAccountProduct.title
          }`,
        },
        { label: "Edit", route: "../" },
        { label: "Default Advertised Skus" },
      ]}
    >
      <Container>
        <Stack spacing={3}>
          <AdvertisedSkusTable
            productGroupId={params.productGroupId}
            productData={productGroup.products}
            currentAdvertisedSkus={currentAdvertisedSkus}
            updateDefaultAdvertisedSkus={updateDefaultAdvertisedSkus}
          />
        </Stack>
      </Container>
    </Page>
  );
}

const headCells = [
  {
    id: "checkbox",
    numeric: false,
    disablePadding: true,
    label: "Advertise",
  },
  {
    id: "imageUrl",
    numeric: false,
    disablePadding: false,
    label: "",
  },
  {
    id: "asin",
    numeric: false,
    disablePadding: false,
    label: "ASIN",
  },
  {
    id: "sku",
    numeric: false,
    disablePadding: false,
    label: "SKU",
  },
  {
    id: "title",
    numeric: false,
    disablePadding: false,
    label: "Product Name",
  },
];

function AdvertisedSkusTable({
  productGroupId,
  productData,
  currentAdvertisedSkus,
  updateDefaultAdvertisedSkus,
}) {
  const [checkedSkus, setCheckedSkus] = useState(currentAdvertisedSkus);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const handleSubmit = () => {
    setLoading(true);
    updateDefaultAdvertisedSkus.mutate(
      { productGroupId, payload: checkedSkus },
      {
        onSuccess: () => {
          enqueueSnackbar("Successfully Saved", { variant: "success" });
          navigate("../");
        },
        onError: (error) => {
          enqueueSnackbar(error, { variant: "error" });
          setLoading(false);
        },
      }
    );
  };
  const mapFunction = (row) => (
    <TableRow
      key={row.id}
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
      }}
    >
      <TableCell component={"th"} scope="row">
        <Checkbox
          checked={checkedSkus.includes(row.sku)}
          onChange={() => {
            if (checkedSkus.includes(row.sku)) {
              setCheckedSkus(checkedSkus.filter((item) => item !== row.sku));
            } else {
              setCheckedSkus([row.sku, ...checkedSkus]);
            }
          }}
        />
      </TableCell>
      <TableCell>
        <Avatar
          variant="square"
          src={row.imageUrl.replace("SS60", "SS368")}
          sx={{ width: 100, height: 100 }}
        />
      </TableCell>
      <TableCell align="left" sx={{ py: 3 }}>
        <Typography variant="body3">{row.asin}</Typography>
      </TableCell>
      <TableCell align="left" sx={{ py: 3 }}>
        <Typography variant="body3">{row.sku}</Typography>
      </TableCell>
      <TableCell align="left" sx={{ py: 3 }}>
        <Typography variant="body3">{row.title}</Typography>
      </TableCell>
    </TableRow>
  );

  return (
    <>
      <Card>
        <Box sx={{ p: 3 }}>
          <SortableTable
            data={productData}
            initialOrderBy="asin"
            headCells={headCells}
            mapFunction={mapFunction}
          ></SortableTable>
        </Box>
      </Card>
      <LoadingButton
        size="large"
        type="button"
        variant="contained"
        loading={loading}
        onClick={handleSubmit}
        sx={{ alignSelf: "flex-end" }}
      >
        {`Save`}
      </LoadingButton>
    </>
  );
}
