import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";
import { forwardRef } from "react";
// @mui
import { Box, Breadcrumbs, Link, Typography } from "@mui/material";
import config from "src/utils/config";
import {
  Link as RouterLink,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { useTheme } from "@mui/material/styles";

// ----------------------------------------------------------------------

const Page = forwardRef(
  ({ children, title = "", meta = {}, breadcrumbs = [], ...other }, ref) => {
    const location = useLocation();
    const theme = useTheme();
    const cleanPath = location.pathname.replace(/\/$/, "").toLowerCase();
    let queryString = "";

    const [searchParams] = useSearchParams();

    // right now only search param to alway be include is pageIndex
    const pageIndex = searchParams.get("pageIndex");
    if (pageIndex) {
      queryString = `?pageIndex=${pageIndex}`;
    }

    const canonicalUrl = `${config.mainUrl}${cleanPath}${queryString}`;

    const finalTitle = `${title} - Acclio`;
    const finalDesc = meta.description || title;
    const finalImageUrl =
      meta.imageUrl || `${config.mainUrl}/static/logo192.png`;
    return (
      <>
        <Helmet>
          <title>{finalTitle}</title>
          <meta name="title" content={finalTitle} />
          <meta name="description" content={finalDesc} />
          <link rel="canonical" href={`${canonicalUrl}`} />

          <meta property="og:title" content={finalTitle} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={canonicalUrl} />
          <meta property="og:description" content={finalDesc} />
          <meta property="og:image" content={finalImageUrl} />

          <meta name="twitter:title" content={finalTitle} />
          <meta name="twitter:description" content={finalDesc} />
          <meta name="twitter:image" content={finalImageUrl} />
          <meta name="twitter:url" content={canonicalUrl} />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>

        <Box ref={ref} {...other}>
          {breadcrumbs.length > 0 && (
            <Breadcrumbs
              aria-label="breadcrumb"
              sx={{ mx: theme.spacing(3), my: theme.spacing(2) }}
            >
              {breadcrumbs.map((x) => {
                if (x.route && x.route.length > 0) {
                  return (
                    <Link
                      key={x.label}
                      component={RouterLink}
                      to={x.route}
                      underline="hover"
                      color={theme.palette.primary.main}
                    >
                      {x.label}
                    </Link>
                  );
                }
                return (
                  <Typography key={x.label} variant="body">
                    {x.label}
                  </Typography>
                );
              })}
            </Breadcrumbs>
          )}
          {children}
        </Box>
      </>
    );
  }
);

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  meta: PropTypes.shape({
    description: PropTypes.string,
  }),
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      route: PropTypes.string,
    })
  ),
};

export default Page;
