import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import FormikTextField from "src/components/formik/FormikTextField";

const headCells = [
  {
    id: "empty",
    numeric: false,
    disablePadding: false,
    label: "",
  },
  {
    id: "seed",
    numeric: true,
    disablePadding: false,
    label: "Seed",
  },
  {
    id: "grow",
    numeric: true,
    disablePadding: false,
    label: "Grow",
  },
  {
    id: "crop",
    numeric: true,
    disablePadding: false,
    label: "Crop",
  },
];

function createData(name, seedProperty, growProperty, cropProperty) {
  return { name, seedProperty, growProperty, cropProperty };
}

const rows = [
  createData(
    "PPP",
    "seedPaidRankTarget",
    "growPaidRankTarget",
    "cropPaidRankTarget"
  ),
  createData("Max PP", "seedPaidRankMax", "growPaidRankMax", "cropPaidRankMax"),
  createData("Min PP", "seedPaidRankMin", "growPaidRankMin", "cropPaidRankMin"),
  createData(
    "POP",
    "seedOrganicRankTarget",
    "growOrganicRankTarget",
    "cropOrganicRankTarget"
  ),
  createData(
    "Max OP",
    "seedOrganicRankMax",
    "growOrganicRankMax",
    "cropOrganicRankMax"
  ),
  createData(
    "MinOP",
    "seedOrganicRankMin",
    "growOrganicRankMin",
    "cropOrganicRankMin"
  ),
];

export default function AmazonPlacementCard(props) {
  const { touched, errors } = props;
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                align={"center"}
                padding={headCell.disablePadding ? "none" : "normal"}
              >
                {headCell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell>{row.name}</TableCell>
              <TableCell>
                <FormikTextField
                  fullWidth
                  label=" "
                  formikName={row.seedProperty}
                  variant="standard"
                  helperText={
                    (touched[row.seedProperty] && errors[row.seedProperty]) ||
                    " "
                  }
                />
              </TableCell>
              <TableCell>
                <FormikTextField
                  fullWidth
                  label=" "
                  formikName={row.growProperty}
                  variant="standard"
                  helperText={
                    (touched[row.growProperty] && errors[row.growProperty]) ||
                    " "
                  }
                />
              </TableCell>
              <TableCell>
                <FormikTextField
                  fullWidth
                  label=" "
                  formikName={row.cropProperty}
                  variant="standard"
                  helperText={
                    (touched[row.cropProperty] && errors[row.cropProperty]) ||
                    " "
                  }
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
