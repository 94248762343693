import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import Iconify from "src/components/Iconify";
import {
  useDeletePartnerUserInvite,
  useResendPartnerUserInvite,
} from "src/hooks/partner";
import { formatDateShortLocalWithTime } from "src/utils/formatTime";

export const InviteUserListDialog = ({
  partnerId,
  invitations = [],
  roles = [],
  open,
  close,
}) => {
  // invite mutations here - Resend, Delete, etc

  const [filter, setFilter] = useState("pending");
  const [removeInProgress, setRemoveInProgress] = useState([]);
  const [resendInProgress, setResendInProgress] = useState([]);

  const resendInvite = useResendPartnerUserInvite();
  const deleteInvite = useDeletePartnerUserInvite();

  const handleResend = async (inviteId) => {
    setResendInProgress((x) => [...x, inviteId]);
    await resendInvite.mutateAsync({ partnerId, inviteId });
    setResendInProgress((x) => x.filter((y) => y !== inviteId));
  };

  const handleDelete = async (inviteId) => {
    setRemoveInProgress((x) => [...x, inviteId]);
    await deleteInvite.mutateAsync({ partnerId, inviteId });
    setRemoveInProgress((x) => x.filter((y) => y !== inviteId));
  };

  const filteredList = invitations
    .map((invite) => {
      const expiresDate = new Date(invite.expires);
      const status = invite.accepted
        ? "accepted"
        : expiresDate > Date.now()
        ? "pending"
        : "expired";
      return { ...invite, status };
    })
    .filter((invite) => invite.status === filter);

  return (
    <Dialog open={open} onClose={close} fullWidth={true} maxWidth="md">
      <DialogTitle>Invited Users</DialogTitle>
      <DialogContent>
        <Stack direction="column" marginTop={1} spacing={2}>
          <TextField
            label="Filter by Status"
            value={filter}
            select
            onChange={(e) => setFilter(e.target.value)}
          >
            <MenuItem value="pending">pending</MenuItem>
            <MenuItem value="expired">expired</MenuItem>
            <MenuItem value="accepted">accepted</MenuItem>
          </TextField>
          {filteredList.length === 0 ? (
            <Typography variant="body2">
              {`No ${filter} invitations exist`}
            </Typography>
          ) : (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Email</TableCell>
                    <TableCell>Created</TableCell>
                    <TableCell>Expires</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>
                      {filter === "accepted" ? "Accepted" : "Actions"}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredList.map((invite) => (
                    <TableRow key={invite.id}>
                      <TableCell>{invite.email}</TableCell>
                      <TableCell>
                        {formatDateShortLocalWithTime(invite.created)}
                      </TableCell>
                      <TableCell>
                        {formatDateShortLocalWithTime(invite.expires)}
                      </TableCell>
                      <TableCell>{invite.status}</TableCell>
                      <TableCell>
                        {filter === "accepted" ? (
                          formatDateShortLocalWithTime(invite.accepted)
                        ) : (
                          <Stack direction="row">
                            {resendInProgress.some((x) => x === invite.id) ? (
                              <CircularProgress size={20} />
                            ) : (
                              <Tooltip title="Resend">
                                <IconButton
                                  size="small"
                                  color="primary"
                                  onClick={() => handleResend(invite.id)}
                                >
                                  <Iconify icon="eva:paper-plane-fill" />
                                </IconButton>
                              </Tooltip>
                            )}
                            {removeInProgress.some((x) => x === invite.id) ? (
                              <CircularProgress size={20} />
                            ) : (
                              <Tooltip title="Delete">
                                <IconButton
                                  size="small"
                                  color="error"
                                  onClick={() => handleDelete(invite.id)}
                                >
                                  <Iconify icon="eva:trash-2-fill" />
                                </IconButton>
                              </Tooltip>
                            )}
                          </Stack>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={close}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
