import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  TextField,
} from "@mui/material";
import { useState } from "react";

export default function AddRoleModal({
  open,
  user = { roles: [] },
  roles = [],
  close,
  handleAddRole,
  isLoading,
}) {
  const [selectedRole, setSelectedRole] = useState();
  const availableRoles = roles.filter(
    (x) => !user?.roles?.some((ur) => ur.id === x.id)
  );

  const handleClose = () => {
    setSelectedRole("");
    close();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Add Role</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Select a role from the list below to add a role to this user
        </DialogContentText>
        <TextField
          autoFocus
          select
          margin="normal"
          id="role"
          value={selectedRole || ""}
          label="Role to Add"
          fullWidth
          onChange={(e) => setSelectedRole(e.target.value)}
        >
          {availableRoles.map((role) => (
            <MenuItem key={role.id} value={role.id}>
              {role.name}
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <LoadingButton
          disabled={!selectedRole}
          loading={isLoading}
          onClick={() => handleAddRole(user.id, selectedRole)}
        >
          Add
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
